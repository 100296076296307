import { DeleteOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { useField } from 'formik'
import React from 'react'

import { isValidRut } from '../../../utils/Validate.util'

import {
  Pill,
  PillContainer,
  InputContainer,
  Label,
  Input,
  ButtonRut,
  ErrorMessage,
} from './styles'
interface IInputRutProps {
  label: string
  name: string
  type: 'text'
  ruts: string[]
  setRuts: React.Dispatch<React.SetStateAction<string[]>>
}

function RutList({
  ruts,
  handleDeleteRut,
}: {
  ruts: string[]
  handleDeleteRut: (rut: string) => () => void
}) {
  if (ruts.length === 0) {
    return <p>No hay ruts ingresados</p>
  }
  return (
    <Row style={{ marginTop: '1rem' }}>
      {ruts.map((rut) => (
        <Col key={rut} span={6}>
          <PillContainer>
            <Pill>
              {rut}
              <button type="button" onClick={handleDeleteRut(rut)}>
                <DeleteOutlined />
              </button>
            </Pill>
          </PillContainer>
        </Col>
      ))}
    </Row>
  )
}

export default function InputRut(props: IInputRutProps) {
  const { label, ...rest } = props
  const { name, type, ruts, setRuts } = rest
  const [field, meta, helpers] = useField(rest)
  const { value, onBlur, onChange } = field
  const { setError } = helpers

  const handleAddRut = () => () => {
    const cleanRut = value
      .trim()
      .toUpperCase()
      .replace(/[^0-9K-]/g, '')
    if (isValidRut(cleanRut) && !ruts.includes(cleanRut)) {
      setRuts((rutList) => [...rutList, cleanRut])
      onChange({ target: { name, value: '' } })
    } else {
      setError('Rut invalido')
    }
  }

  const handleDeleteRut = (rut: string) => () => {
    setRuts((rutList) => rutList.filter((rutItem) => rutItem !== rut))
  }
  return (
    <InputContainer>
      <Label htmlFor={name}>{label}</Label>
      <div>
        <div className="inputbtn">
          <Input
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            type={type}
            name={name}
          />
          <ButtonRut color="#E56910" type="default" onClick={handleAddRut()}>
            Agregar Nuevo RUT
          </ButtonRut>
        </div>
        {meta.touched && meta.error ? (
          <ErrorMessage>{meta.error}</ErrorMessage>
        ) : null}
        <RutList ruts={ruts} handleDeleteRut={handleDeleteRut} />
      </div>
    </InputContainer>
  )
}
