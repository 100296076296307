import { PlusCircleOutlined } from '@ant-design/icons'
import { Col, Row, Input, Button, Drawer } from 'antd'
import { matchSorter } from 'match-sorter'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CreateUserForm } from '../../../components/Form/Forms/CreateUserForm'
import Loader from '../../../components/Loader'
import userService from '../../../services/usuarioService.service'
import { downloadFileCSV } from '../../../utils/Statistics.util'
import { UserList } from '../components/UserList'
import { IAdmin } from '../components/types'

import { H1, ContainerDrawer, ContainerSearch } from './styles'
const { Search } = Input

export function Users() {
  const [filteredUsers, setFilteredUsers] = useState<Array<IAdmin>>([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [users, setUsers] = useState<Array<IAdmin>>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    const fetchall = async () => {
      setLoading(true)
      setError(false)
      try {
        const data = await userService.getAllUsers()
        setUsers(data)
        setFilteredUsers(data)
      } catch (error: any) {
        setError(true)
      }
      setLoading(false)
    }
    fetchall()
  }, [])

  const onSearch = (value: string) => {
    const w = matchSorter(users, value, { keys: ['nome', 'email', 'ruts'] })
    setFilteredUsers(w)
  }

  const showDrawer = () => {
    setOpenDrawer(true)
  }

  const onClose = () => {
    setOpenDrawer(false)
  }
  const downloadUsers = async () => {
    setLoading(true)
    try {
      await downloadFileCSV()
      toast.success('Descarga exitosa')
    } catch (error: any) {
      toast.error('Error al descargar')
    } finally {
      setLoading(false)
    }
  }

  if (error) return <Redirect to="/error" />
  if (loading) return <Loader isLoading />
  return (
    <>
      <Row justify="center" style={{ maxWidth: '90rem' }}>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <H1>Usuarios</H1>
        </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <ContainerSearch>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              onClick={showDrawer}>
              Crear Usuario
            </Button>
            <Search
              placeholder="Buscar por nombre email o rut"
              enterButton="Buscar"
              size="large"
              onSearch={onSearch}
            />
            <Button
              type="default"
              size="large"
              onClick={() => {
                downloadUsers()
              }}>
              Descargar CSV Usuarios
            </Button>
          </ContainerSearch>
        </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <UserList list={filteredUsers} setUsers={setFilteredUsers} />
        </Col>
      </Row>
      <Drawer
        title="Crear Usuario"
        placement="right"
        width={720}
        onClose={onClose}
        visible={openDrawer}>
        <ContainerDrawer>
          <CreateUserForm
            setUsers={setUsers}
            setFilteredUsers={setFilteredUsers}
            setOpenDrawer={setOpenDrawer}
          />
        </ContainerDrawer>
      </Drawer>
    </>
  )
}
