import { Popover } from 'antd'
import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import { AuthContext } from '../../../../context/Auth.context'

import AvatarPopover from './AvatarPopover'
import { UlNav, NavAdmin, Avatar } from './styles'

const listItems = [
  { key: 1, name: 'Usuarios' },
  { key: 2, name: 'Facturas' },
  { key: 3, name: 'Noticias' },
]

export function Nav() {
  const [active, setActive] = useState(1)
  const { user } = useContext(AuthContext)
  useEffect(() => {
    const path = window.location.pathname
    const pathArray = path.split('/')
    const pathName = pathArray[2]
    const item = listItems.find((item) => item.name.toLowerCase() === pathName)
    if (item) {
      setActive(item.key)
    }
  }, [])
  const handleClickInTabNav = (e: number) => {
    setActive(e)
  }
  return (
    <NavAdmin>
      <UlNav>
        {listItems.map((item) => (
          <li
            onClick={() => {
              handleClickInTabNav(item.key)
            }}
            key={item.key}>
            <Link
              className={active === item.key ? 'activeTab' : ''}
              to={`/admin/${item.name.toLowerCase()}`}>
              {item.name}
            </Link>
          </li>
        ))}
        <li>
          <Popover
            placement="bottomLeft"
            title={`Hola, ${user.nome}`}
            content={<AvatarPopover email={user.email} />}
            trigger="click">
            <Avatar size="large">{user.nome.toUpperCase()[0]}</Avatar>
          </Popover>
        </li>
      </UlNav>
    </NavAdmin>
  )
}
