import React from 'react'

import { UserCard } from './UserCard'
import { IUserListProps } from './types'

export const UserList = (props: IUserListProps) => {
  const { list, setUsers } = props
  if (list.length < 1) {
    return <h2>No existen usuarios!</h2>
  }
  return (
    <div>
      {list.map((user) => (
        <UserCard user={user} key={user.email} setUsers={setUsers} />
      ))}
    </div>
  )
}
