/* eslint-disable react/jsx-props-no-spreading */
import { FileImageOutlined } from '@ant-design/icons'
import { useField } from 'formik'
import React, { useEffect, useState } from 'react'

import {
  InputContainer,
  LabelFile,
  ErrorMessage,
  Input,
  ImagePreview,
} from './styles'
interface IInputFileProps {
  name: string
  type: 'file'
  accept: string
  file: File | null
  setFile: (file: File | null) => void
  initialUrl?: string
}

export default function InputFileNews(props: IInputFileProps) {
  const { setFile, file, initialUrl, ...rest } = props
  const [url, setUrl] = useState<string>(initialUrl || '')
  const [field, meta] = useField(rest)
  const { onChange } = field

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
    const file = e.target.files?.[0]
    if (file) {
      setFile(file)
    }
  }
  useEffect(() => {
    if (file) {
      setUrl(URL.createObjectURL(file))
    } else {
      if (initialUrl) {
        setUrl(initialUrl)
      } else {
        setUrl('')
      }
    }
  }, [file])
  return (
    <InputContainer>
      <LabelFile>
        <Input
          {...field}
          {...props}
          onChange={(e) => {
            handleFileChange(e)
          }}
        />
        <FileImageOutlined />
        <p>Haz click para seleccionar imagen</p>
      </LabelFile>
      <ImagePreview>
        {url ? (
          <img src={url} alt="preview upload image" />
        ) : (
          <p>No hay imagen seleccionada</p>
        )}
      </ImagePreview>
      <div>
        {meta.touched && meta.error ? (
          <ErrorMessage>{meta.error}</ErrorMessage>
        ) : null}
      </div>
    </InputContainer>
  )
}
