import axios from 'axios'

import firebase from '../utils/firebase/firebase.util'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

api.interceptors.request.use(async (request) => {
  request.headers.authorization = await firebase
    .auth()
    .currentUser?.getIdToken()
  return request
})

api.interceptors.response.use(undefined, async (err) => {
  if (err?.response?.status === 403) {
    return Promise.reject(new Error('Forbidden'))
  }

  return Promise.reject(err)
})

export default api
