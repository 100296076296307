import { Button } from 'antd'
import { Formik, Form, FormikHelpers } from 'formik'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { IAdmin } from '../../../pages/Admin/components/types'
import userService from '../../../services/usuarioService.service'
import { validateUpdateUser } from '../../../utils/Error.utils'
import InputRut from '../CustomInputs/InputRut'
import InputText from '../CustomInputs/InputText'
interface IBaseValues {
  name: string
  position: string
  phone: string
}

export interface IFormValues extends IBaseValues {
  rut: string
}

export interface IValues extends IBaseValues {
  ruts: string[]
  email: string
}

interface IUpdateUserFormProps {
  values: IValues
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setUsers: React.Dispatch<React.SetStateAction<Array<IAdmin>>>
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}
export const UpdateUserForm = (props: IUpdateUserFormProps) => {
  const { values, setLoading, setUsers, setOpenModal, loading } = props

  const [ruts, setRuts] = useState<Array<string>>([])
  const { name, position, phone, email } = values

  useEffect(() => {
    setRuts(values.ruts)
  }, [values.ruts])

  const onSubmit = async (
    formikValues: IFormValues,
    actions: FormikHelpers<IFormValues>,
  ) => {
    const { name, position, phone } = formikValues

    const updatedUser: IAdmin = {
      nome: name,
      email,
      jobPosition: position,
      ruts: ruts,
      aprovado: true,
      telefono: phone,
    }
    setLoading(true)
    try {
      await userService.updateUsuario(email, updatedUser)
      setUsers((users) => {
        const newUsers = users.map((user) => {
          if (user.email === email) {
            return updatedUser
          }
          return user
        })
        return [...newUsers]
      })
      toast.success('Usuario editado con éxito')
    } catch (e) {
      toast.error('Error al editar usuario')
    }
    setLoading(false)
    actions.setSubmitting(false)
    actions.resetForm({
      values: {
        name,
        position,
        rut: '',
        phone,
      },
    })
    setOpenModal(false)
  }
  return (
    <Formik
      initialValues={{ name, phone, position, rut: '' }}
      validate={validateUpdateUser}
      onSubmit={onSubmit}>
      <Form>
        <InputText
          label="Nombre"
          name="name"
          type="text"
          placeholder="John Doe"
        />
        <InputText
          label="Posición"
          name="position"
          type="text"
          placeholder="CEO"
        />
        <InputText
          label="Teléfono"
          name="phone"
          type="text"
          placeholder="+569 1234 5678"
        />
        <InputRut
          label="Ingrese rut"
          name="rut"
          type="text"
          ruts={ruts}
          setRuts={setRuts}
        />
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          disabled={loading}>
          Guardar Cambios
        </Button>
      </Form>
    </Formik>
  )
}
