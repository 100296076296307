import { Button } from 'antd'
import styled from 'styled-components'

export const ButtonRut = styled(Button)`
  margin-left: 0.5rem;
  color: #e56910;
  border-color: #e56910;
  height: 100%;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  .inputbtn {
    display: flex;
    align-items: center;
  }
`
export const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.2rem;
`
export const Input = styled.input`
  padding: 0.3rem;
  font-size: 0.9rem;
  border: 1px solid hsl(0, 0%, 80%);
`
export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
`

export const PillContainer = styled.div`
  display: flex;
  align-items: center;
  jsutify-content: center;
  margin-bottom: 0.5rem;
`
export const Pill = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  background-color: hsl(0, 0%, 80%);
  border-radius: 1rem;
  button {
    background-color: transparent;
    padding: 4px;
    border: none;
    margin-left: 0.5rem;
    color: hsl(0, 40%, 50%);
  }
  button:hover {
    background-color: hsl(0, 0%, 85%);
  }
`
export const InputContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

export const InputTxt = styled.input`
  padding: 0.3rem;
  font-size: 0.9rem;
  border: 1px solid #e8e8e8;
`
export const InputContainerChk = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`
export const LabelChk = styled.label`
  display: flex;
  font-size: 1rem;
  margin-bottom: 0.2rem;
  p {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0 0 0 10px;
  }
`
export const InputChk = styled.input`
  padding: 0.3rem;
  font-size: 0.9rem;
  border: 1px solid #e8e8e8;
`

export const InputTArea = styled.textarea`
  padding: 0.3rem;
  font-size: 0.9rem;
  border: 1px solid #e8e8e8;
  height: 200px;
`
export const LabelFile = styled.label`
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  border: 1px solid hsl(25, 87%, 48%);
  color: hsl(25, 87%, 48%);
  display: flex;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  width: fit-content;
  &:hover {
    background-color: hsl(25, 87%, 48%);
    color: white;
  }
  input[type='file'] {
    display: none;
  }
  p {
    margin: 0 0 0 6px;
  }
  span {
    font-size: 1.3rem;
  }
`

export const ImagePreview = styled.div`
  display: flex;
  flex-direction: column;
  img {
    object-fit: cover;
    width: 300px;
  }
  p {
    margin: 8px 0 0 0;
    font-size: 0.7rem;
    font-weight: 600;
  }
`
