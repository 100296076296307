import React from 'react'

import {
  fileNameToURLStorage,
  isValidMasiveUploadFileName,
} from '../../../../utils/File.util'

import { InvalidFileLi } from './styles'

interface IFileItemProps {
  file: File
}
export const FileItem = (props: IFileItemProps) => {
  const { file } = props
  if (isValidMasiveUploadFileName(file)) {
    return <li key={file.name}>{fileNameToURLStorage(file.name)}</li>
  }
  return (
    <InvalidFileLi key={file.name}>
      <p>
        <span>formato inválido!</span>
        {file.name}
      </p>
    </InvalidFileLi>
  )
}
