import { renderToString } from 'react-dom/server'

import FirstEmail from '../Mail/FirstEmail'
import { IUserAdminValues } from '../components/Form/Forms/CreateUserAdminForm'
import firebase from '../utils/firebase/firebase.util'

import api from './api_functions.service'
import mailService from './mailService.service'

const userService = {
  createAdmin: async (userAdmin: IUserAdminValues) => {
    const { name, email, password, superKey } = userAdmin
    const newUser = {
      nome: name,
      email,
      password,
      superKey,
    }
    const { data } = await api.post('/usuarios/createAdmin', newUser)
    return data
  },
  updateAdmin: async (userAdmin: { nome: string; email: string }) => {
    const { data } = await api.put(
      `/usuarios/updateAdmin/${userAdmin.email}`,
      userAdmin,
    )
    return data
  },
  getUsuario: async () => {
    const { data } = await api.get('/usuarios')
    return data
  },
  getAllUsers: async () => {
    const { data } = await api.get('/usuarios/all')
    return data
  },
  getAllUsersAdmin: async () => {
    const { data } = await api.get('/usuarios/alladmin')
    return data
  },
  updateUsuario: async (email, request) => {
    await api.put(`/usuarios/${email}`, request)
  },
  getEmail() {
    return localStorage.getItem('@Chile_uid')
  },
  login: async (email, password) => {
    const userCredentials = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)

    return userCredentials
  },
  register: async ({ password, nome, telefono, email, ruts, jobPosition }) => {
    const newUser = {
      nome,
      telefono,
      email,
      ruts,
      password,
      jobPosition,
    }
    const { data } = await api.post('/usuarios', newUser)
    return data
  },
  async sendWelcomeEmail(email) {
    const template = renderToString(FirstEmail())
    await mailService.sendMail({
      email,
      subject: 'Bienvenido a nuestra Plataforma!',
      html: template,
    })
  },
  async resetPassword(email) {
    await firebase.auth().sendPasswordResetEmail(email)
  },
  async deleteAccount(email: string): Promise<string> {
    const { data } = await api.delete(`/usuarios/${email}`)
    return data.id
  },
  async userIsAdmin() {
    const tokenInfo = await firebase.auth().currentUser?.getIdTokenResult()
    if (tokenInfo) {
      if (tokenInfo.claims.role === 'admin') {
        return true
      }
    }
    return false
  },
}

export default userService
