import { useCallback, useState } from 'react'

export function useDialog() {
  const [isDialogOpen, setDialogOpen] = useState(false)

  const onCloseDialog = useCallback(() => {
    setDialogOpen(false)
  }, [])

  const onOpenDialog = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const toggleDialog = useCallback(() => {
    setDialogOpen((isDialogOpen) => !isDialogOpen)
  }, [])

  return {
    isDialogOpen,
    setDialogOpen,
    onCloseDialog,
    onOpenDialog,
    toggleDialog,
  }
}
