import { LoadingOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Formik, Form, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { ISuperAdmin } from '../../../pages/Admin/components/Super/SuperUserCard'
import userService from '../../../services/usuarioService.service'
import { validateCreateUserAdmin } from '../../../utils/Error.utils'
import InputText from '../CustomInputs/InputText'

import { LoaderContainerCreateUser } from './styles'
export interface IUserAdminValues {
  name: string
  email: string
  password: string
  passwordConfirm: string
  superKey: string
}

const initValues: IUserAdminValues = {
  name: '',
  email: '',
  password: '',
  passwordConfirm: '',
  superKey: '',
}

interface ICreateUserAdminFormProps {
  setUsers: React.Dispatch<React.SetStateAction<Array<ISuperAdmin>>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const CreateUserAdminForm = (props: ICreateUserAdminFormProps) => {
  const { setUsers, setOpen } = props
  const [loading, setLoading] = useState<boolean>(false)
  const onSubmit = async (
    values: IUserAdminValues,
    actions: FormikHelpers<IUserAdminValues>,
  ) => {
    setLoading(true)
    try {
      await userService.createAdmin(values)
      setUsers((listUsers) => {
        return [...listUsers, { nome: values.name, email: values.email }]
      })
      setOpen(false)
      toast.success('Usuario creado con exito')
    } catch (err) {
      toast.error('Error al crear usuario')
    }
    setLoading(false)
    actions.setSubmitting(false)
    actions.resetForm()
  }

  return (
    <>
      <Formik
        initialValues={initValues}
        validate={validateCreateUserAdmin}
        onSubmit={onSubmit}>
        <Form>
          <InputText
            label="Nombre"
            name="name"
            type="text"
            placeholder="John Doe"
          />
          <InputText
            label="Email"
            name="email"
            type="email"
            placeholder="admin@safira.com"
          />
          <InputText
            label="Password"
            name="password"
            type="password"
            placeholder="********"
          />
          <InputText
            label="Repite password"
            name="passwordConfirm"
            type="password"
            placeholder="********"
          />
          <InputText
            label="SuperKey"
            name="superKey"
            type="password"
            placeholder=""
          />
          {loading ? (
            <LoaderContainerCreateUser>
              <LoadingOutlined />
            </LoaderContainerCreateUser>
          ) : (
            <Button htmlType="submit" type="primary" size="large">
              Crear Usuario
            </Button>
          )}
        </Form>
      </Formik>
    </>
  )
}
