import React, { useState, useEffect } from 'react'

import usuarioService from '../services/usuarioService.service'
import { to } from '../utils/Async.util'
import firebase from '../utils/firebase/firebase.util'
import './IAuth.context'

const EMPTY_USER = {
  nome: '',
  telefono: '',
  email: '',
  ruts: [] as string[],
  aprovado: '',
  jobPosition: '',
  isEmpty: true,
  isAdmin: false,
}

interface IAuthContext {
  readonly user: typeof EMPTY_USER
  readonly [index: string]: any
}

export const AuthContext = React.createContext<IAuthContext>({
  user: EMPTY_USER,
})

interface IAuthProviderProps {
  children: React.ReactNode
}

function AuthProvider(props: IAuthProviderProps) {
  const { children } = props

  const [user, setUser] = useState(EMPTY_USER)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loginLoading, setLoginLoading] = useState(false)
  const [restoringSession, setRestoringSession] = useState(true)
  const [loginError, setLoginError] = useState<any>(null)
  const [error, setError] = useState<any>(null)

  useEffect(() => {
    const unsuscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser) => {
        if (firebaseUser) {
          setIsAuthenticated(true)
          const [error, userDB] = await to(usuarioService.getUsuario())
          if (error) {
            setRestoringSession(false)
            setError(error)
          } else {
            setRestoringSession(false)
            const isAdmin = await usuarioService.userIsAdmin()
            setUser({ ...userDB, isAdmin })
            localStorage.setItem('@Chile_uid', userDB.email)
          }
        } else {
          setIsAuthenticated(false)
          setUser(EMPTY_USER)
        }
        setLoading(false)
      })

    return unsuscribe
  }, [])

  async function login({ email, password }) {
    setLoginLoading(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, _userCredentials] = await to(
      usuarioService.login(email, password),
    )
    if (error) {
      setLoginError(error)
    }
    setLoginError(null)
    setLoginLoading(false)
  }

  function addRut(rut = '') {
    const newRuts = [...user.ruts, rut]
    setUser({ ...user, ruts: newRuts })
  }

  function removeRut(indexToRemove) {
    const newRuts = user.ruts.filter((_, i) => i !== indexToRemove)
    setUser({
      ...user,
      ruts: newRuts,
    })
  }

  async function cleanAuthContext() {
    setUser(EMPTY_USER)
    localStorage.setItem('@Chile_uid', '')
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        addRut,
        removeRut,
        isAuthenticated,
        error,
        loading,
        login,
        loginLoading,
        loginError,
        setUser,
        cleanAuthContext,
        restoringSession,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider }
