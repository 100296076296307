import { FileOutlined } from '@ant-design/icons'
import { Empty } from 'antd'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import Loader from '../../../../components/Loader/Loader'
import {
  getAllFilesDownTree,
  NEWSIMAGE_ROOT,
} from '../../../../services/upload.service'

import { FilterHeader } from './FilterHeader'
import {
  CardSelectedContainer,
  GridContainer,
  ListFiles,
  MainContainer,
  TitleContainer,
} from './styles'
export function Main() {
  const [files, setFiles] = useState<Array<string>>([])
  const [filteredFiles, setFilteredFiles] = useState<Array<string>>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const removeFiles = (filesToBeDeleted: Array<string>) => {
    setFiles((prev) => prev.filter((file) => !filesToBeDeleted.includes(file)))
    setFilteredFiles([])
  }
  useEffect(() => {
    const getFiles = async () => {
      try {
        setLoading(true)
        setError(false)
        const files = await getAllFilesDownTree('/', NEWSIMAGE_ROOT)
        setFiles(files)
        setFilteredFiles(files)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    getFiles()
  }, [])
  if (error) return <Redirect to="/error" />
  if (loading) return <Loader isLoading />
  return (
    <MainContainer>
      <TitleContainer>
        <h1>Filtro de Facturas</h1>
      </TitleContainer>
      <GridContainer>
        <div>
          <FilterHeader
            allFiles={files}
            filterFiles={setFilteredFiles}
            filteredFiles={filteredFiles}
            removeFiles={removeFiles}
          />
        </div>
        <CardSelectedContainer>
          <h3>Lista de archivos seleccionados</h3>
          {filteredFiles.length === 0 ? (
            <Empty description={<span>0 archivos seleccionados</span>} />
          ) : (
            <ListFiles>
              {filteredFiles.map((path) => (
                <div key={path}>
                  <FileOutlined />
                  <p>{path}</p>
                </div>
              ))}
            </ListFiles>
          )}
        </CardSelectedContainer>
      </GridContainer>
    </MainContainer>
  )
}
