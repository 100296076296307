import { LoadingOutlined } from '@ant-design/icons'
import { Formik, Form, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { IAdmin } from '../../../pages/Admin/components/types'
import userService from '../../../services/usuarioService.service'
import { validateCreateUser } from '../../../utils/Error.utils'
import InputRut from '../CustomInputs/InputRut'
import InputText from '../CustomInputs/InputText'

import { LoaderContainerCreateUser, ButtonCreateContainer } from './styles'

export interface IValues {
  name: string
  email: string
  position: string
  phone: string
  password: string
  passwordConfirm: string
  rut: string
}

const initValues: IValues = {
  name: '',
  email: '',
  position: '',
  phone: '',
  password: '',
  passwordConfirm: '',
  rut: '',
}

interface ICreateUserFormProps {
  setUsers: React.Dispatch<React.SetStateAction<Array<IAdmin>>>
  setFilteredUsers: React.Dispatch<React.SetStateAction<Array<IAdmin>>>

  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
}
export const CreateUserForm = (props: ICreateUserFormProps) => {
  const { setUsers, setOpenDrawer, setFilteredUsers } = props
  const [ruts, setRuts] = useState<Array<string>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const onSubmit = async (values: IValues, actions: FormikHelpers<IValues>) => {
    const newUser = {
      nome: values.name,
      email: values.email,
      password: values.password,
      jobPosition: values.position,
      ruts: ruts,
      telefono: values.phone,
      aprovado: false,
    }
    setLoading(true)
    try {
      await userService.register(newUser)
      setUsers((prev) => [...prev, newUser])
      setFilteredUsers((prev) => [...prev, newUser])
      toast.success('Usuario creado con exito')
      setOpenDrawer(false)
    } catch (e) {
      toast.error('Error al crear usuario')
    }
    setLoading(false)
    actions.setSubmitting(false)
    actions.resetForm()
    setRuts([])
  }

  return (
    <>
      {loading && (
        <LoaderContainerCreateUser>
          <LoadingOutlined />
        </LoaderContainerCreateUser>
      )}

      <Formik
        initialValues={initValues}
        validate={validateCreateUser}
        onSubmit={onSubmit}>
        <Form>
          <InputText
            label="Nombre"
            name="name"
            type="text"
            placeholder="John Doe"
          />
          <InputText
            label="Email"
            name="email"
            type="email"
            placeholder="jhondoe@safira.com"
          />
          <InputText
            label="Posición"
            name="position"
            type="text"
            placeholder="CEO"
          />
          <InputText
            label="Teléfono"
            name="phone"
            type="text"
            placeholder="+569 1234 5678"
          />
          <InputText
            label="Password"
            name="password"
            type="password"
            placeholder="********"
          />
          <InputText
            label="Repite password"
            name="passwordConfirm"
            type="password"
            placeholder="********"
          />
          <InputRut
            label="Rut Empresa"
            name="rut"
            type="text"
            ruts={ruts}
            setRuts={setRuts}
          />
          <ButtonCreateContainer>
            <button type="submit" disabled={loading}>
              Crear Usuario
              {loading && <LoadingOutlined />}
            </button>
          </ButtonCreateContainer>
        </Form>
      </Formik>
    </>
  )
}
