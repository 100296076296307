import { Avatar as AvtAnt } from 'antd'
import styled from 'styled-components'

export const NavAdmin = styled.nav`
  display: flex;
  align-items: center;
`
export const Avatar = styled(AvtAnt)`
  background-color: white;
  margin: 0 0 3px 10px;
  span {
    color: #e56910;
    font-size: 1rem;
    font-weight: 500;
  }
`
export const UlNav = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  font-weight: 600;

  li {
    padding: 0 10px;
  }

  li:hover {
    cursor: pointer;
  }
  a {
    color: hsl(0, 0%, 70%);
  }
  a:hover {
    color: hsl(0, 0%, 100%);
  }
  .activeTab {
    color: white;
  }
`
export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  header {
    position: sticky;
    top: 0;
    z-index: 800;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #032677;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 50px;
  background-color: white;
`
export const ContainerLogo = styled.div`
  display: flex;
  align-items: center;
`
export const Logo = styled.img`
  height: 45px;
  width: auto;
  margin-bottom: 4px;
`
export const AvatarPopoverContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const ButtonAvatarPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #e56910;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  p {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
`
