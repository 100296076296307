import api from './api_functions.service'

const mailService = {
  sendMail: async ({ email, subject, html }) => {
    const data = {
      email,
      subject,
      html,
    }
    const response = await api.post('/mail', data)
    return response
  },
}

export default mailService
