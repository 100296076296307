/* eslint-disable react/jsx-props-no-spreading */
import { useField } from 'formik'
import React from 'react'

import { InputContainerText, Label, InputTxt, ErrorMessage } from './styles'
interface TextInputProps {
  label: string
  name: string
  type: 'text' | 'email' | 'password'
  placeholder: string
}

export default function InputText(props: TextInputProps) {
  const { label, ...rest } = props
  const [field, meta] = useField(rest)
  return (
    <InputContainerText>
      <Label htmlFor={props.name}>{label}</Label>
      <InputTxt {...field} {...props} />
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </InputContainerText>
  )
}
