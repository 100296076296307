import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import React from 'react'
import { ToastContainer } from 'react-toastify'

import { AuthProvider } from './context/Auth.context'
import { PontoProvider } from './context/Ponto.context'
import { SimulationProvider } from './context/Simulation.context'
import Routes from './routes'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78be21',
      contrastText: '#fff',
    },
  },
})

const App = () => {
  return (
    <>
      <ToastContainer />
      <MuiThemeProvider theme={theme}>
        <AuthProvider>
          <PontoProvider>
            <SimulationProvider>
              <Routes />
            </SimulationProvider>
          </PontoProvider>
        </AuthProvider>
      </MuiThemeProvider>
    </>
  )
}

export default App
