import { max, uniq } from 'lodash'
export type TYearMonths = { year: string; months: Array<string> }
export type TYearMonthsArray = Array<TYearMonths>
export type TRutYearMonths = {
  rut: string
  yearMonthList: TYearMonthsArray
}
export type TRutYearMonthsArray = Array<TRutYearMonths>

export const getMax = (list: Array<string>) => {
  const numberList = list.map((item) => parseInt(item))
  const maxNumber = max(numberList)
  if (maxNumber) {
    if (maxNumber < 10) {
      return `0${maxNumber}`
    }
    return maxNumber.toString()
  }
  return ''
}

export const getYearMonthListFromReferenceList = (
  list: Array<{ fullpath: string; name: string }>,
): TYearMonthsArray => {
  const yearsWithMonts: Array<{ year: string; months: Array<string> }> = []

  for (const item of list) {
    const chunks = item.fullpath.split('/')
    const yearChunk = chunks[1]
    const monthChunk = chunks[2]

    const yearList = yearsWithMonts.map((item) => item.year)

    if (!yearList.includes(yearChunk)) {
      yearsWithMonts.push({ year: yearChunk, months: [monthChunk] })
    } else {
      const yearIndex = yearList.indexOf(yearChunk)
      const monthList = yearsWithMonts[yearIndex].months
      if (!monthList.includes(monthChunk)) {
        yearsWithMonts[yearIndex].months.push(monthChunk)
      }
    }
  }
  return yearsWithMonts
}

export const getBiggestYear = (yearMonthsArray: TYearMonthsArray) => {
  const years = yearMonthsArray.map((item) => item.year)
  return getMax(years)
}

export const getMonthListFromYear = (data: TRutYearMonths, year: string) => {
  const yearMonthsObject = data.yearMonthList.find((item) => item.year === year)
  if (yearMonthsObject) {
    return yearMonthsObject.months
  }
  return data.yearMonthList[0].months
}

export const selectRutYearMonthsFromSelectedUserRut = (
  rutYearMonthsArray: TRutYearMonthsArray,
  rut: string,
) => {
  const rutYearMonthsObject = rutYearMonthsArray.find(
    (item) => item.rut === rut,
  )
  if (rutYearMonthsObject) {
    return rutYearMonthsObject
  }
  return rutYearMonthsArray[0]
}

export const getYearListFromRut = (data: TRutYearMonthsArray, rut: string) => {
  const rutYearMonthsObject = selectRutYearMonthsFromSelectedUserRut(data, rut)
  return rutYearMonthsObject.yearMonthList.map((item) => item.year)
}

export const getMonthListFromRutYear = (
  data: TRutYearMonthsArray,
  rut,
  year: string,
) => {
  const rutYearMonthsObject = selectRutYearMonthsFromSelectedUserRut(data, rut)
  return getMonthListFromYear(rutYearMonthsObject, year)
}

export const getDifferentRuts = (list: Array<string>) => {
  const newList = list.map((item) => item.split('/')[0])
  return uniq(newList)
}

export const getUniqueYearMonths = (
  list: Array<string>,
): { [key: string]: Array<string> } => {
  const newList = list.map(
    (item) => item.split('/')[1] + '-' + item.split('/')[2],
  )
  const yearMonth = uniq(newList)
  return yearMonth.reduce((object, actualValueArray) => {
    const year = actualValueArray.split('-')[0]
    const month = actualValueArray.split('-')[1]
    if (object[year]) {
      object[year].push(month)
    } else {
      object[year] = [month]
    }
    return object
  }, {})
}
