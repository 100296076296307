import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e8e8e8;
  padding: 20px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 43%;
  h5 {
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    font-size: 1rem;
  }
  p {
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  width: 20%;
`
export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 7%;
`
export const RutContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 30%;
`
export const RutPill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 999px;
  padding: 4px 8px;
  p {
    margin: 0;
    font-size: 0.6rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    color: #e56910;
    font-size: 3rem;
  }
`
export const LoadingContainerCenter = styled(LoadingContainer)`
  justify-content: center;
  margin: 20px;
`
