import styled from 'styled-components'
export const ContainerNews = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem 9rem;
  form {
    width: 100%;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #032677;
  background-color: white;
  color: #032677;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 12px;
  font-size: 0.9rem;
  font-weight: 600;
  &:hover {
    background-color: #032677;
    color: white;
  }
`
