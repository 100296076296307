import styled from 'styled-components'

export const ErrorPageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
`

export const CardError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: 10px;
  -webkit-box-shadow: 3px 3px 9px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 3px 3px 9px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 3px 3px 9px 0px rgba(0, 0, 0, 0.35);
`

export const Logo = styled.img`
  width: 160px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 20px;
`

export const Button = styled.button`
  background-color: #064ff9;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    background-color: #4a81f7;
  }
`
