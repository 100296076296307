import styled from 'styled-components'
export const LoaderContainerCreateUser = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 20px;
  span {
    color: #e56910;
    font-size: 2rem;
  }
`
export const ButtonCreateContainer = styled.div`
  display: flex;
  button {
    padding: 10px 20px;
    color: white;
    background-color: #e56910;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
  }
  span {
    margin-left: 10px;
    color: white;
    font-size: 1.4rem;
    font-weight: 800;
  }
`
export const FlexContainer = styled.div`
  display: flex;
`
