import { Button } from 'antd'
import styled from 'styled-components'

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`
export const FilterContainer = styled(FlexRow)`
  gap: 20px;
  margin-bottom: 20px;
`
export const CardContainer = styled(FlexColumn)`
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
`
export const CardSelectedContainer = styled(CardContainer)`
  max-height: 516px;
  overflow-y: scroll;
  padding: 20px 40px;
  h3 {
    margin: 0 0 20px 0;
    color: rgba(0, 0, 0, 0.6);
  }
`

export const SelectorContainer = styled(FlexColumn)`
  margin: 0 20px;
  h3 {
    margin: 0;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
  }
`
export const CheckBoxContainer = styled(FlexRow)`
  margin: 0;
  label {
    margin-left: 4px;
    font-size: 0.8rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
  }
`
export const MainContainer = styled(FlexColumn)`
  padding: 0;
  margin: 0;
  gap: 20px;
`
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
`
export const TitleContainer = styled(FlexColumn)`
  padding: 30px 0 0 0;
`
export const TitleSection = styled.h3`
  margin: 0 0 10px 20px;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.6);
`
export const SearchContainer = styled(FlexColumn)`
  padding: 0 20px;
  margin-bottom: 20px;
  p {
    margin: 0;
    font-size: 0.8rem;
  }
`
export const RutContainer = styled(FlexColumn)`
  margin-bottom: 30px;
`
export const ButtonDelete = styled(Button)`
  margin-top: 20px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    font-size: 1rem;
  }
  p {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
  }
`
export const ListFiles = styled(FlexColumn)`
  p {
    margin: 0;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.6);
  }
  div {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 2px;
  }
  span {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.6);
  }
`
export const ModalContent = styled(FlexColumn)`
  span {
    font-size: 3rem;
    color: #e56910;
  }
`
