import styled from 'styled-components'

export const ContainerTab = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const FirstRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`
export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`
export const PathContainer = styled(FlexCenter)`
  padding: 20px 0 20px 0;
  color: rgba(0, 0, 0, 0.5);
  p {
    font-weight: 600;
    padding-top: 2px;
    margin: 0 2px;
    font-size: 0.7rem;
  }
  p:hover {
    text-decoration: underline;
  }
  span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
  }
`
export const ContainerButtonPath = styled.div`
  display: flex;
  gap: 20px;
`
export const SecondaryButton = styled.button`
  border: 1px solid hsl(25, 87%, 48%);
  background: white;
  color: hsl(25, 87%, 48%);
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: hsl(25, 0%, 90%);
  }
`

export const BlueButton = styled(SecondaryButton)`
  border: none;
  background: hsl(222, 95%, 24%);
  color: white;
  &:hover {
    background: hsl(222, 95%, 34%);
  }
`
export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
`
export const TitleFacturas = styled.h1`
  margin: 30px 30px;
`
export const Table = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  .headerTable {
    font-weight: 600;
  }
  div:last-child {
    border-bottom: none;
  }
`
export const Row = styled(FlexCenter)`
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  :hover {
    background-color: hsl(0, 0%, 88%);
  }
  p {
    text-overflow: ellipsis;
  }
`

export const Field = styled(FlexCenter)<{ header?: boolean }>`
  color: rgba(0, 0, 0, 0.8);
  margin-left: 20px;
  p {
    margin: 20px 0 20px 4px;
  }
  span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.2rem;
  }
`
export const FileName = styled(Field)`
  width: 50%;
  p:hover {
    text-decoration: underline;
  }
`
export const SecondaryField = styled(Field)`
  color: ${(props) => (props.header ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)')};
`

export const FileType = styled(SecondaryField)`
  width: 20%;
`
export const FileSize = styled(SecondaryField)`
  width: 20%;
`
export const FileUpdate = styled(SecondaryField)`
  width: 10%;
`
export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
`

export const PopoverContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 200px;
  padding: 20px;
`
export const DangerButton = styled(SecondaryButton)`
  border: none;
  background: hsl(7, 70%, 50%);
  color: white;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: hsl(7, 70%, 60%);
  }
`
export const ListContainer = styled.div`
  display: flex;
  p {
    font-weight: 600;
    font-size: 0.7rem;
  }
`
export const UlContainer = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
  }
`
export const Ul = styled.ul`
  list-style: none;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.8rem;
`
export const InvalidFileLi = styled.li`
  color: hsl(7, 70%, 50%);
  p {
    margin: 0;
    font-weight: 600;
    font-size: 0.8rem;
  }
`
export const Label = styled.label`
  border: none;
  background: hsl(25, 87%, 48%);
  color: white;
  font-weight: 600;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 5px;
  input[type='file'] {
    display: none;
  }
  &:hover {
    background: hsl(25, 87%, 68%);
  }
`
export const InnerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Button = styled.button`
  border: none;
  background: hsl(25, 87%, 48%);
  color: white;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: hsl(25, 87%, 68%);
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`
export const DeleteHeaderContainer = styled(FlexCenter)`
  padding: 20px;
`
export const CheckBoxContainer = styled(Field)`
  margin-left: 20px;
  width: 2%;
`
