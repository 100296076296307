/* eslint-disable react/jsx-props-no-spreading */
import { useField } from 'formik'
import React from 'react'

import { Label, InputContainer, InputTArea, ErrorMessage } from './styles'
interface TextInputProps {
  label: string
  name: string
  type: 'text'
  placeholder: string
}

export default function InputTextArea(props: TextInputProps) {
  const { label, ...rest } = props
  const [field, meta] = useField(rest)
  return (
    <InputContainer>
      <Label htmlFor={props.name}>{label}</Label>
      <InputTArea {...field} {...props} />
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </InputContainer>
  )
}
