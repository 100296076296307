import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CreateNewsForm } from '../../../../../components/Form/Forms/CreateNewsForm'
import Loader from '../../../../../components/Loader'

import { ContainerNews, ButtonContainer, Button } from './styles'

export function CreateNews() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  if (loading) {
    return <Loader isLoading />
  }
  return (
    <ContainerNews>
      <ButtonContainer>
        <Button
          onClick={() => {
            history.push('/admin/noticias')
          }}>
          Volver a la lista
        </Button>
      </ButtonContainer>
      <CreateNewsForm setLoading={setLoading} />
    </ContainerNews>
  )
}
