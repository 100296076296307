import { Modal, Spin } from 'antd'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import { uploadBatch } from '../../../../services/upload.service'
import { isValidMasiveUploadFileName } from '../../../../utils/File.util'

import { FileList } from './FileList'
import {
  InnerModal,
  Label,
  ButtonContainer,
  Button,
  SecondaryButton,
} from './styles'
interface IProps {
  isOpen: boolean
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>
  setPath: React.Dispatch<React.SetStateAction<string>>
}
export function ModalMultipleUpload(props: IProps) {
  const { isOpen, toggleModal, setPath } = props
  const [fileList, setFileList] = useState<FileList | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFileList(e.target.files)
    }
  }

  const handleUpload = async () => {
    if (fileList) {
      const arrayF = Array.from(fileList)
      const invalidFiles = arrayF.some(
        (file) => !isValidMasiveUploadFileName(file),
      )
      if (invalidFiles) {
        toast.error(
          'Error al subir archivos algunos no tienen el formato correcto',
        )
      } else {
        const allPromises: Promise<void>[] = []
        for (let i = 0; i < arrayF.length; i++) {
          allPromises.push(uploadBatch(arrayF[i]))
        }
        setLoading(true)
        try {
          await Promise.allSettled(allPromises)
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve('')
            }, 1000)
          })
          toast.success('Archivos subidos correctamente')
          setLoading(false)
        } catch (e) {
          setError(true)
          setLoading(false)
        }
      }
    }
  }
  if (error) {
    return <Redirect to="/error" />
  }
  return (
    <Modal
      title="Carga Masiva"
      visible={isOpen}
      footer={null}
      width={500}
      onCancel={() => {
        toggleModal(false)
        setFileList(null)
      }}>
      <InnerModal>
        {loading ? (
          <Spin />
        ) : (
          <>
            <form>
              {fileList === null ? (
                <Label>
                  Haz click para seleccionar archivos
                  <input
                    type="file"
                    multiple
                    onChange={(e) => {
                      handleFileChange(e)
                    }}
                  />
                </Label>
              ) : null}
            </form>
            <FileList fileList={fileList} />
            {fileList && fileList.length > 0 ? (
              <ButtonContainer>
                <Button
                  onClick={async () => {
                    await handleUpload()

                    setFileList(null)
                    setPath('*')
                    toggleModal(false)
                  }}>
                  Subir Archivos
                </Button>
                <SecondaryButton
                  onClick={() => {
                    toggleModal(false)
                    setFileList(null)
                  }}>
                  Cancelar
                </SecondaryButton>
              </ButtonContainer>
            ) : null}
          </>
        )}
      </InnerModal>
    </Modal>
  )
}
