import { FolderOutlined, FileOutlined } from '@ant-design/icons'
import { Spin, Popover } from 'antd'
import firebase from 'firebase'
import React, { useState } from 'react'

import { dayMonthYear } from '../../../../utils/Date.util'
import { convertBytesToReadable } from '../../../../utils/File.util'

import { DeleteHeader, IPathInfo } from './DeleteHeader'
import { IFile } from './FileViewer'
import { ModalMultipleUpload } from './ModalMultipleUpload'
import { Path } from './Path'
import { PopoverContent } from './PopoverContent'
import {
  ContainerList,
  Table,
  Row,
  FileName,
  FileType,
  FileSize,
  FileUpdate,
  SpinContainer,
  CheckBoxContainer,
} from './styles'
interface IListProps {
  prefixes: firebase.storage.Reference[]
  items: IFile[]
  path: string
  setPath: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
}

export function ListFolder(props: IListProps) {
  const { prefixes, items, setPath, path, loading } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [listSelectedFiles, setListSelectedFiles] = useState<Array<IPathInfo>>(
    [],
  )
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    pathInfo: IPathInfo,
  ) => {
    if (e.target.checked) {
      setListSelectedFiles((files) => [...files, pathInfo])
    } else {
      setListSelectedFiles((files) =>
        files.filter((file) => file.path !== pathInfo.path),
      )
    }
  }
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setListSelectedFiles([
        ...items.map((file) => {
          return { path: file.ref.fullPath, isDirectory: false }
        }),
        ...prefixes.map((directory) => {
          return { path: directory.fullPath, isDirectory: true }
        }),
      ])
    } else {
      setListSelectedFiles([])
    }
  }

  return (
    <ContainerList>
      <Table>
        <Row>
          {listSelectedFiles.length > 0 ? (
            <DeleteHeader
              listFiles={listSelectedFiles}
              setPath={setPath}
              setListSelectedFiles={setListSelectedFiles}
            />
          ) : (
            <Path path={path} setPath={setPath} toggleModal={setIsModalOpen} />
          )}
        </Row>
        <Row className="headerTable">
          <CheckBoxContainer>
            <input
              type="checkbox"
              checked={
                listSelectedFiles.length === items.length + prefixes.length
              }
              onChange={(e) => handleSelectAll(e)}
            />
          </CheckBoxContainer>
          <FileName header>
            <p>Nombre</p>
          </FileName>
          <FileType header>
            <p>Tipo</p>
          </FileType>
          <FileSize header>
            <p>Tamaño</p>
          </FileSize>
          <FileUpdate header>
            <p>Últ. Mod.</p>
          </FileUpdate>
        </Row>
        {loading ? (
          <Row>
            <SpinContainer>
              <Spin size="large" />
            </SpinContainer>
          </Row>
        ) : (
          <>
            {prefixes.map((directory) => (
              <Row key={directory.fullPath}>
                <CheckBoxContainer>
                  <input
                    type="checkbox"
                    checked={listSelectedFiles
                      .map((file) => file.path)
                      .includes(directory.fullPath)}
                    onChange={(e) => {
                      handleCheckboxChange(e, {
                        path: directory.fullPath,
                        isDirectory: true,
                      })
                    }}
                  />
                </CheckBoxContainer>
                <FileName
                  onClick={() => {
                    setPath(`/${directory.fullPath}`)
                  }}>
                  <FolderOutlined />
                  <p>{directory.name}</p>
                </FileName>
                <FileType>
                  <p>Carpeta</p>
                </FileType>
                <FileSize>
                  <p>--</p>
                </FileSize>
                <FileUpdate>
                  <p>--</p>
                </FileUpdate>
              </Row>
            ))}
            {items.map((file) => (
              <Row key={file.ref.fullPath}>
                <CheckBoxContainer>
                  <input
                    type="checkbox"
                    checked={listSelectedFiles
                      .map((file) => file.path)
                      .includes(file.ref.fullPath)}
                    onChange={(e) => {
                      handleCheckboxChange(e, {
                        path: file.ref.fullPath,
                        isDirectory: false,
                      })
                    }}
                  />
                </CheckBoxContainer>
                <Popover
                  placement="bottomLeft"
                  content={
                    <PopoverContent
                      fullpath={file.ref.fullPath}
                      setPath={setPath}
                    />
                  }
                  trigger="click">
                  <FileName>
                    <FileOutlined />
                    <p>{file.ref.name}</p>
                  </FileName>
                </Popover>
                <FileType>
                  <p>{file.metadata.contentType}</p>
                </FileType>
                <FileSize>
                  <p>{convertBytesToReadable(file.metadata.size)} </p>
                </FileSize>
                <FileUpdate>
                  <p>{dayMonthYear(file.metadata.updated)}</p>
                </FileUpdate>
              </Row>
            ))}
          </>
        )}
      </Table>
      <ModalMultipleUpload
        isOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        setPath={setPath}
      />
    </ContainerList>
  )
}
