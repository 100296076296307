import firebase from 'firebase'

import api from './api_functions.service'
type TCreateNews = {
  title: string
  subtitle: string
  lead: string
  text: string
  imageUrl: string
  refString: string
  favorite: boolean
}

export type TNewsFromAPI = TCreateNews & {
  id: string
  data: firebase.firestore.Timestamp
}
export const NewsService = {
  async create(props: TCreateNews) {
    const { title, subtitle, text, lead, imageUrl, refString, favorite } = props
    const { data } = await api.post('/news', {
      title,
      subtitle,
      text,
      lead,
      imageUrl,
      refString,
      favorite,
    })
    return data
  },

  async listnews() {
    const { data } = await api.get('/news')
    return data.data
  },

  async getNewsById(id: string): Promise<TNewsFromAPI> {
    const { data } = await api.get(`/news/${id}`)
    return data.data
  },
  async update(id: string, props: TCreateNews) {
    const { title, subtitle, text, lead, imageUrl, refString, favorite } = props
    await api.put(`/news/${id}`, {
      title,
      subtitle,
      text,
      lead,
      imageUrl,
      refString,
      favorite,
    })
  },
  async deleteNews(id: string) {
    await api.delete(`/news/${id}`)
  },
}
