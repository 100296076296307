import React from 'react'

import { Main } from '../components/FacturasSearch/Main'

import { FacturaContainer } from './styles'
export function FacturasSearch() {
  return (
    <FacturaContainer>
      <Main />
    </FacturaContainer>
  )
}
