import { Button } from 'antd'
import { Formik, Form, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { NewsService, TNewsFromAPI } from '../../../services/news.service'
import { updateNewsImage } from '../../../services/upload.service'
import { validateCreateNews } from '../../../utils/Error.utils'
import InputCheckbox from '../CustomInputs/InputCheckbox'
import InputFileNews from '../CustomInputs/InputFileNews'
import InputText from '../CustomInputs/InputText'
import InputTextArea from '../CustomInputs/InputTextArea'

import { IValuesCreateNews } from './CreateNewsForm'

interface ICreateNewsFormProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  initValues: TNewsFromAPI
}
export const UpdateNewsForm = (props: ICreateNewsFormProps) => {
  const { setLoading, initValues } = props

  const [file, setFile] = useState<File | null>(null)
  const [checked, setChecked] = useState<boolean>(initValues.favorite)
  const history = useHistory()
  const onSubmit = async (
    values: IValuesCreateNews,
    actions: FormikHelpers<IValuesCreateNews>,
  ) => {
    setLoading(true)
    try {
      let urlUpdate = initValues.imageUrl
      let refStringUpdate = initValues.refString
      if (values.file) {
        if (file) {
          const { url, refString } = await updateNewsImage(
            file,
            refStringUpdate,
          )
          urlUpdate = url
          refStringUpdate = refString
        }
      }
      await NewsService.update(initValues.id, {
        title: values.title,
        subtitle: values.subtitle,
        text: values.text,
        lead: values.lead,
        imageUrl: urlUpdate,
        refString: refStringUpdate,
        favorite: checked,
      })
      toast.success('Noticia editada con éxito')
    } catch (err) {
      toast.error('Error al editar noticia')
    }
    history.push('/admin/noticias')
    setLoading(false)
    setChecked(false)
    setFile(null)
    actions.setSubmitting(false)
    actions.resetForm()
  }

  return (
    <Formik
      initialValues={{
        title: initValues.title,
        subtitle: initValues.subtitle,
        text: initValues.text,
        lead: initValues.lead,
        file: '',
      }}
      validate={validateCreateNews}
      onSubmit={onSubmit}>
      <Form>
        <InputText
          label="Título"
          name="title"
          type="text"
          placeholder="Título noticia"
        />
        <InputText
          label="Subtítulo"
          name="subtitle"
          type="text"
          placeholder="Subtítulo noticia"
        />
        <InputText
          label="Lead"
          name="lead"
          type="text"
          placeholder="Lead noticia"
        />
        <InputTextArea
          label="Contenido"
          name="text"
          type="text"
          placeholder="Contenido de la noticia"
        />
        <InputFileNews
          name="file"
          type="file"
          accept="image/*"
          initialUrl={initValues.imageUrl}
          file={file}
          setFile={setFile}
        />
        <InputCheckbox
          checked={checked}
          setChecked={setChecked}
          label="Marcar como favorita"
          type="checkbox"
          name="favorite"
        />
        <Button htmlType="submit" type="primary" size="large">
          Editar Noticia
        </Button>
      </Form>
    </Formik>
  )
}
