import { Backdrop } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Spin } from 'antd'
import { bool } from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: 'white',
  },
}))

function Loader({ isLoading }) {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <img
        src="/img/logo_luce.png"
        style={{ marginBottom: '2rem' }}
        alt="loading"
      />
      <Spin size="large" />
    </Backdrop>
  )
}

Loader.propTypes = {
  isLoading: bool,
}

export default Loader
