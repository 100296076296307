import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { Spin, Modal } from 'antd'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import {
  deleteFile,
  getAllFilesDownTree,
} from '../../../../services/upload.service'

import {
  DeleteHeaderContainer,
  DangerButton,
  SpinContainer,
  ListContainer,
  UlContainer,
  Ul,
} from './styles'

export interface IPathInfo {
  path: string
  isDirectory: boolean
}

interface ISelectionProps {
  listFiles: Array<IPathInfo>
  setPath: React.Dispatch<React.SetStateAction<string>>
  setListSelectedFiles: React.Dispatch<React.SetStateAction<Array<IPathInfo>>>
}

export const DeleteHeader = (props: ISelectionProps) => {
  const { listFiles, setPath, setListSelectedFiles } = props
  const [isOpen, setIsOpen] = useState(false)
  const [allFiles, setAllFiles] = useState<Array<string>>([])
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    try {
      setLoading(true)
      for (const file of allFiles) {
        await deleteFile(file)
      }
      setIsOpen(false)
      setPath('*')
      toast.success('Archivos eliminados correctamente')
    } catch (e) {
      toast.error('Error al eliminar archivos')
    }
    setListSelectedFiles([])
    setAllFiles([])
    setLoading(false)
  }

  return (
    <>
      <DeleteHeaderContainer>
        <DangerButton
          disabled={loading}
          onClick={async () => {
            setLoading(true)
            setAllFiles([])
            for (const file of listFiles) {
              if (file.isDirectory) {
                const all = await getAllFilesDownTree(file.path)
                setAllFiles((prev) => [...prev, ...all])
              } else {
                setAllFiles((prev) => [...prev, file.path])
              }
            }
            setIsOpen(true)
            setLoading(false)
          }}>
          <DeleteOutlined />
          Borrar seleccionados
          {loading && <LoadingOutlined className="ml-5" />}
        </DangerButton>
      </DeleteHeaderContainer>
      <Modal
        title="Desea eliminar este archivo?"
        visible={isOpen}
        onOk={() => {
          handleDelete()
        }}
        onCancel={() => {
          setIsOpen(false)
        }}>
        {loading ? (
          <SpinContainer>
            <Spin />
          </SpinContainer>
        ) : (
          <ListContainer>
            <UlContainer>
              <Ul>
                {allFiles.map((fileName) => (
                  <li key={fileName}>{fileName}</li>
                ))}
              </Ul>
            </UlContainer>
          </ListContainer>
        )}
      </Modal>
    </>
  )
}
