import { UserAddOutlined } from '@ant-design/icons'
import { Drawer } from 'antd'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { CreateUserAdminForm } from '../../../components/Form/Forms/CreateUserAdminForm'
import Loader from '../../../components/Loader'
import userService from '../../../services/usuarioService.service'
import { SuperUserCard, ISuperAdmin } from '../components/Super/SuperUserCard'

import {
  ContainerDrawer,
  SuperContainer,
  GridUser,
  ButtonContainer,
} from './styles'

export function SuperUser() {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [users, setUsers] = useState<Array<ISuperAdmin>>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    const fetchall = async () => {
      setLoading(true)
      setError(false)
      try {
        const data = await userService.getAllUsersAdmin()
        setUsers(data)
      } catch (error: any) {
        setError(true)
      }
      setLoading(false)
    }
    fetchall()
  }, [])

  const showDrawer = () => {
    setOpenDrawer(true)
  }

  const onClose = () => {
    setOpenDrawer(false)
  }
  if (error) return <Redirect to="/error" />
  if (loading) return <Loader isLoading />
  return (
    <SuperContainer>
      <h1>Admins</h1>
      <ButtonContainer>
        <button onClick={showDrawer}>
          <UserAddOutlined /> Crear Nuevo Admin
        </button>
      </ButtonContainer>
      <GridUser>
        {users.map((user) => (
          <SuperUserCard user={user} key={user.email} setUsers={setUsers} />
        ))}
      </GridUser>
      <Drawer
        title="Crear Usuario"
        placement="right"
        width={720}
        onClose={onClose}
        visible={openDrawer}>
        <ContainerDrawer>
          <CreateUserAdminForm setUsers={setUsers} setOpen={setOpenDrawer} />
        </ContainerDrawer>
      </Drawer>
    </SuperContainer>
  )
}
