import React from 'react'
import styled from 'styled-components'

const FirstEmailContainer = styled.div`
  width: '100%';
  height: '100%';
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
`

const StyledImage = styled.img`
  height: '100%';
  width: 'auto';
  align-self: 'center';
`

const imgUrl =
  'https://firebasestorage.googleapis.com/v0/b/plataforma-chile-8f0da.appspot.com/o/emkt_aceite_de_cadastro.png?alt=media&token=e4cffa15-9fad-4a7a-b18e-48186820e507'

function FirstEmail() {
  return (
    <FirstEmailContainer>
      <StyledImage src={imgUrl} />
    </FirstEmailContainer>
  )
}

export default FirstEmail
