import React from 'react'

import {
  NewsContentCollapseContainer,
  ImageNews,
  ParagraphContainer,
} from './styles'

interface INewsContentCollapseProps {
  text: string
  url?: string
  open?: boolean
}

export function NewsContentCollapse(props: INewsContentCollapseProps) {
  const { text, url, open } = props
  const paragraphs = text.split('\n')
  if (!open) return null
  return (
    <NewsContentCollapseContainer>
      <ImageNews>
        <img src={url} />
      </ImageNews>
      {paragraphs.map((text) => (
        <ParagraphContainer key={text}>
          <p>{text}</p>
        </ParagraphContainer>
      ))}
    </NewsContentCollapseContainer>
  )
}
