import {
  FormOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Avatar, Modal, Button } from 'antd'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import {
  UpdateUserForm,
  IValues,
} from '../../../components/Form/Forms/UpdateUserForm'
import userService from '../../../services/usuarioService.service'

import {
  CardContainer,
  TextContainer,
  ButtonContainer,
  AvatarContainer,
  RutContainer,
  RutPill,
  LoadingContainer,
  LoadingContainerCenter,
} from './styles'
import { IUserCardProps } from './types'

export function UserCard(props: IUserCardProps) {
  const { user, setUsers } = props
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const initValues: IValues = {
    name: user.nome,
    email: user.email,
    position: user.jobPosition,
    ruts: user.ruts,
    phone: user.telefono,
  }

  const confirmDelete = async () => {
    setConfirmLoading(true)
    try {
      const id = await userService.deleteAccount(user.email)
      if (id) {
        toast.success('Usuario eliminado')
      }
      setOpenDeleteModal(false)
      setUsers((userList) =>
        userList.filter((currentUser) => currentUser.email !== user.email),
      )
    } catch (error) {
      toast.error('Error al eliminar usuario')
    } finally {
      setConfirmLoading(false)
    }
  }

  return (
    <CardContainer>
      <AvatarContainer>
        <Avatar
          size={54}
          style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
          {user.nome[0]}
        </Avatar>
      </AvatarContainer>
      <TextContainer>
        <h5>{user.nome}</h5>
        <p>{user.email}</p>
      </TextContainer>
      <RutContainer>
        {user.ruts &&
          user.ruts.map((rut) => (
            <RutPill key={rut}>
              <p>{rut}</p>
            </RutPill>
          ))}
      </RutContainer>
      <ButtonContainer>
        <Button
          type="default"
          icon={<DeleteOutlined />}
          onClick={() => {
            setOpenDeleteModal(true)
          }}
        />
        <Button
          type="default"
          icon={<FormOutlined />}
          onClick={() => setOpen(true)}
        />
      </ButtonContainer>

      <Modal
        visible={open}
        title="Editar Usuario"
        centered
        okText="Guardar Cambios"
        cancelText="Cancelar"
        footer={null}
        onCancel={() => {
          setOpen(false)
        }}
        width={800}>
        <>
          {loading && (
            <LoadingContainer>
              <LoadingOutlined />
            </LoadingContainer>
          )}
          <UpdateUserForm
            values={initValues}
            setLoading={setLoading}
            loading={loading}
            setUsers={setUsers}
            setOpenModal={setOpen}
          />
        </>
      </Modal>
      <Modal
        visible={openDeleteModal}
        title="Borrar Usuario?"
        centered
        okText="Borrar"
        onOk={confirmDelete}
        cancelText="Cancelar"
        onCancel={() => {
          setOpenDeleteModal(false)
        }}
        width={400}>
        <>
          {confirmLoading && (
            <LoadingContainerCenter>
              <LoadingOutlined />
            </LoadingContainerCenter>
          )}
        </>
      </Modal>
    </CardContainer>
  )
}
