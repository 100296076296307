import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { UpdateUserAdminForm } from '../../../../components/Form/Forms/UpdateUserAdminForm'
import userService from '../../../../services/usuarioService.service'

import {
  Container,
  TextContainer,
  ButtonContainer,
  Button,
  ButtonDelete,
  LoadingContainerCenter,
} from './styles'

export interface ISuperAdmin {
  email: string
  nome: string
}

interface ICardProps {
  user: ISuperAdmin
  setUsers: React.Dispatch<React.SetStateAction<Array<ISuperAdmin>>>
}

export function SuperUserCard(props: ICardProps) {
  const { user, setUsers } = props
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const confirmDelete = async () => {
    setConfirmLoading(true)
    try {
      const id = await userService.deleteAccount(user.email)
      if (id) {
        toast.success('Usuario eliminado')
      }
      setOpenDeleteModal(false)
      setUsers((userList) =>
        userList.filter((currentUser) => currentUser.email !== user.email),
      )
    } catch (error) {
      toast.error('Error al eliminar usuario')
    } finally {
      setConfirmLoading(false)
    }
  }
  return (
    <>
      <Container>
        <TextContainer>
          <h4>{user.nome}</h4>
          <p>{user.email}</p>
        </TextContainer>
        <ButtonContainer>
          <Button
            onClick={() => {
              setOpenUpdateModal(true)
            }}>
            <EditOutlined />
            Editar
          </Button>
          <ButtonDelete
            onClick={() => {
              setOpenDeleteModal(true)
            }}>
            <DeleteOutlined />
            Eliminar
          </ButtonDelete>
        </ButtonContainer>
      </Container>
      <Modal
        visible={openDeleteModal}
        width={400}
        onOk={confirmDelete}
        title="Borrar?"
        okText="Sí"
        cancelText="No"
        onCancel={() => {
          setOpenDeleteModal(false)
        }}>
        {confirmLoading && (
          <LoadingContainerCenter>
            <LoadingOutlined />
          </LoadingContainerCenter>
        )}
      </Modal>
      <Modal
        visible={openUpdateModal}
        title="Editar Usuario"
        centered
        okText="Guardar Cambios"
        cancelText="Cancelar"
        footer={null}
        onCancel={() => {
          setOpenUpdateModal(false)
        }}
        width={800}>
        <>
          <UpdateUserAdminForm
            values={{ name: user.nome, email: user.email }}
            setLoading={setLoading}
            loading={loading}
            setUsers={setUsers}
            setOpenModal={setOpenUpdateModal}
          />
        </>
      </Modal>
    </>
  )
}
