import { LoadingOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Formik, Form, FormikHelpers } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'

import { ISuperAdmin } from '../../../pages/Admin/components/Super/SuperUserCard'
import userService from '../../../services/usuarioService.service'
import { validateUpdateUserAdmin } from '../../../utils/Error.utils'
import InputText from '../CustomInputs/InputText'

import { LoaderContainerCreateUser } from './styles'
interface IUserAdminValues {
  name: string
  email: string
}
interface IFormValues {
  name: string
}
interface IUpdateUserAdminFormProps {
  values: IUserAdminValues
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setUsers: React.Dispatch<React.SetStateAction<Array<ISuperAdmin>>>
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}
export const UpdateUserAdminForm = (prop: IUpdateUserAdminFormProps) => {
  const { values, setLoading, setUsers, setOpenModal, loading } = prop

  const onSubmit = async (
    formikValues: IFormValues,
    actions: FormikHelpers<IFormValues>,
  ) => {
    const { name } = formikValues

    const updatedUser: ISuperAdmin = {
      nome: name,
      email: values.email,
    }
    setLoading(true)
    try {
      await userService.updateAdmin(updatedUser)
      setUsers((users) => {
        const newUsers = users.map((user) => {
          if (user.email === values.email) {
            return updatedUser
          }
          return user
        })
        return [...newUsers]
      })
      toast.success('Usuario editado con éxito')
    } catch (e) {
      toast.error('Error al editar usuario')
    }
    setLoading(false)
    actions.setSubmitting(false)
    actions.resetForm({
      values: {
        name,
      },
    })
    setOpenModal(false)
  }

  return (
    <>
      <Formik
        initialValues={{ name: values.name }}
        validate={validateUpdateUserAdmin}
        onSubmit={onSubmit}>
        <Form>
          <InputText
            label="Nombre"
            name="name"
            type="text"
            placeholder="Soy Admin"
          />
          {loading ? (
            <LoaderContainerCreateUser>
              <LoadingOutlined />
            </LoaderContainerCreateUser>
          ) : (
            <Button htmlType="submit" type="primary" size="large">
              Editar
            </Button>
          )}
        </Form>
      </Formik>
    </>
  )
}
