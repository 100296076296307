import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { Input, Collapse, Modal } from 'antd'
import React, { useState, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import { deleteFile } from '../../../../services/upload.service'
import {
  getUniqueYearMonths,
  getDifferentRuts,
} from '../../../../utils/Facturas.util'

import {
  CardContainer,
  CheckBoxContainer,
  FilterContainer,
  SelectorContainer,
  TitleSection,
  SearchContainer,
  RutContainer,
  ButtonDelete,
  ModalContent,
} from './styles'
type Props = {
  filterFiles: React.Dispatch<React.SetStateAction<Array<string>>>
  allFiles: Array<string>
  filteredFiles: Array<string>
  removeFiles: (filesToBeDeleted: Array<string>) => void
}
const { Search } = Input
const { Panel } = Collapse

export function FilterHeader(props: Props) {
  const { allFiles, filterFiles, filteredFiles, removeFiles } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedYearMonths, setSelectedYearMonths] = useState<Array<string>>(
    [],
  )
  const [selectedRuts, setSelectedRuts] = useState<Array<string>>([])

  const [fileName, setFileName] = useState('')

  const [isDeleting, setIsDeleting] = useState(false)

  const handleChangeMonth = (
    event: React.ChangeEvent<HTMLInputElement>,
    year: string,
  ) => {
    const { checked, value } = event.target
    const yearMonthSelected = year + '/' + value
    if (checked) {
      setSelectedYearMonths((prev) => [...prev, yearMonthSelected])
    } else {
      setSelectedYearMonths((prev) =>
        prev.filter((yearMonth) => yearMonth !== yearMonthSelected),
      )
    }
  }

  const handleChangeRut = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target
    if (checked) {
      setSelectedRuts((prev) => [...prev, value])
    } else {
      setSelectedRuts((prev) => prev.filter((rut) => rut !== value))
    }
  }

  const handleDelete = async () => {
    try {
      setIsDeleting(true)
      for (const file of filteredFiles) {
        await deleteFile(file)
      }
      toast.success('Archivos eliminados correctamente')
      removeFiles(filteredFiles)
      setSelectedYearMonths([])
      setSelectedRuts([])
      setFileName('')
    } catch (e) {
      toast.error('Error al eliminar archivos')
    } finally {
      setIsDeleting(false)
      setIsModalOpen(false)
    }
  }

  const onSearch = (value: string) => {
    setFileName(value)
  }

  const listYearMonth = useMemo(() => getUniqueYearMonths(allFiles), [allFiles])
  const listRuts = useMemo(() => getDifferentRuts(allFiles), [allFiles])
  useEffect(() => {
    let filteredFiles = allFiles.filter((file) => {
      const yearMonth = file.split('/')[1] + '/' + file.split('/')[2]
      return selectedYearMonths.includes(yearMonth)
    })

    if (fileName) {
      filteredFiles = filteredFiles.filter((file) => {
        const name = file.split('/')[3]
        return name.includes(fileName)
      })
    }
    if (selectedRuts.length > 0) {
      filteredFiles = filteredFiles.filter((file) => {
        const rut = file.split('/')[0]
        return !selectedRuts.includes(rut)
      })
    }
    filterFiles(filteredFiles)
  }, [selectedYearMonths, fileName, selectedRuts])
  return (
    <CardContainer>
      <TitleSection>Selecciona Meses</TitleSection>
      <FilterContainer>
        {Object.keys(listYearMonth).map((year) => (
          <SelectorContainer key={year}>
            <h3>{year}</h3>
            {listYearMonth[year].map((month) => (
              <CheckBoxContainer key={month}>
                <input
                  type="checkbox"
                  value={month}
                  onChange={(event) => handleChangeMonth(event, year)}
                  checked={selectedYearMonths.includes(year + '/' + month)}
                />
                <label>{month}</label>
              </CheckBoxContainer>
            ))}
          </SelectorContainer>
        ))}
      </FilterContainer>
      <Collapse>
        <Panel header="Filtrar por nombre" key="1">
          <SearchContainer>
            <Search
              placeholder="Buscar por nombre de archivo"
              enterButton="Buscar"
              size="middle"
              onSearch={onSearch}
            />
            <p>
              {fileName ? (
                <p>
                  Texto para match: <strong>{fileName}</strong>
                </p>
              ) : (
                <p>No hay texto ingresado</p>
              )}
            </p>
          </SearchContainer>
        </Panel>
        <Panel header="Excluir RUT" key="2">
          <RutContainer>
            <SelectorContainer>
              {listRuts.map((rut) => (
                <CheckBoxContainer key={rut}>
                  <input
                    type="checkbox"
                    value={rut}
                    onChange={(event) => {
                      handleChangeRut(event)
                    }}
                  />
                  <label>{`${rut.substring(0, rut.length - 1)}-${rut.charAt(
                    rut.length - 1,
                  )}`}</label>
                </CheckBoxContainer>
              ))}
            </SelectorContainer>
          </RutContainer>
        </Panel>
      </Collapse>
      <ButtonDelete
        type="primary"
        disabled={filteredFiles.length < 1 || isDeleting}
        onClick={() => {
          setIsModalOpen(true)
        }}>
        <div>
          {isDeleting ? <LoadingOutlined /> : <DeleteOutlined />}
          <p>
            {filteredFiles.length < 1
              ? ' (No hay archivos para borrar)'
              : 'Borrar Archivos Seleccionados'}
          </p>
        </div>
      </ButtonDelete>
      <Modal
        title="Eliminar Archivos"
        visible={isModalOpen}
        onOk={() => {
          handleDelete()
        }}
        onCancel={() => {
          setIsModalOpen(false)
        }}>
        <ModalContent>
          {isDeleting ? (
            <LoadingOutlined />
          ) : (
            <p>
              ¿Eliminar <strong>{filteredFiles.length}</strong> archivos?
            </p>
          )}
        </ModalContent>
      </Modal>
    </CardContainer>
  )
}
