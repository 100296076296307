import { Layout } from 'antd'
import React from 'react'

import LogoBranco from '../../../../assets/logo_branco.png'
import { IContainerAdminProps } from '../types'

import { Nav } from './Nav'
import {
  LayoutContainer,
  ContentContainer,
  ContainerLogo,
  Logo,
} from './styles'
const { Header, Content } = Layout

export function ContainerAdmin(props: IContainerAdminProps) {
  return (
    <LayoutContainer>
      <Layout>
        <Header>
          <ContainerLogo>
            <Logo src={LogoBranco} />
          </ContainerLogo>
          <Nav />
        </Header>
        <Content>
          <ContentContainer>{props.children}</ContentContainer>
        </Content>
      </Layout>
    </LayoutContainer>
  )
}
