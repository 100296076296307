import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { ContainerAdmin } from './components/Layout/ContainerAdmin'
import { CreateNews } from './components/News/NewsCreate/NewsCreate'
import { NewsList } from './components/News/NewsList/NewsList'
import { NewsUpdate } from './components/News/NewsUpdate/NewsUpdate'
import { Facturas } from './pages/Facturas'
import { FacturasSearch } from './pages/FacturasSearch'
import { SuperUser } from './pages/SuperAdmin'
import { Users } from './pages/Users'
import { ContainerList } from './styles'
export default function Admin() {
  return (
    <>
      <ContainerAdmin>
        <ContainerList>
          <Switch>
            <Route path="/admin/usuarios" component={Users} />
            <Route exact path="/admin/super" component={SuperUser} />
            <Route
              exact
              path="/admin/facturas/search"
              component={FacturasSearch}
            />
            <Route path="/admin/facturas" component={Facturas} />
            <Route exact path="/admin/noticias" component={NewsList} />
            <Route exact path="/admin/noticias/create" component={CreateNews} />
            <Route
              exact
              path="/admin/noticias/update/:id"
              component={NewsUpdate}
            />
            <Route path="/admin">
              <Redirect to="/admin/usuarios" />
            </Route>
          </Switch>
        </ContainerList>
      </ContainerAdmin>
    </>
  )
}
