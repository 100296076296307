import es from 'date-fns/locale/es'
export const defaultLocale = es

export const monthToText = (month: number) => {
  if (month === 0) {
    return 'Enero'
  }
  if (month === 1) {
    return 'Febrero'
  }
  if (month === 2) {
    return 'Marzo'
  }
  if (month === 3) {
    return 'Abril'
  }
  if (month === 4) {
    return 'Mayo'
  }
  if (month === 5) {
    return 'Junio'
  }
  if (month === 6) {
    return 'Julio'
  }
  if (month === 7) {
    return 'Agosto'
  }

  if (month === 8) {
    return 'Septiembre'
  }
  if (month === 9) {
    return 'Octubre'
  }
  if (month === 10) {
    return 'Noviembre'
  }
  if (month === 11) {
    return 'Diciembre'
  }
}

export const dayMonthYear = (date: string) => {
  const d = new Date(date)
  return `${d.getDate()} ${monthToText(d.getMonth())?.slice(
    0,
    3,
  )} ${d.getFullYear()}`
}

export const getParsedDate = (firestoreTimeObject: {
  _seconds: number
  _nanoseconds: number
}) => {
  const { _nanoseconds, _seconds } = firestoreTimeObject
  const date = new Date(_seconds * 1000 + _nanoseconds / 1000000000)
  return {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  }
}
