/* eslint-disable indent */
import {
  FormOutlined,
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Button, Modal, Tag } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { NewsService } from '../../../../../services/news.service'
import { deleteNewsImage } from '../../../../../services/upload.service'
import { getParsedDate } from '../../../../../utils/Date.util'

import { NewsContentCollapse } from './NewsContentCollapse'
import { INews } from './NewsList'
import {
  FlexColumn,
  ButtonContainer,
  ContainerNewsCard,
  TextContainer,
  ClickableText,
  ContainerCenter,
  TagContainer,
} from './styles'

interface INewsCardProps {
  news: INews
  setNews: React.Dispatch<React.SetStateAction<INews[]>>
}

const confirmDelete =
  (
    id: string,
    refString: string,
    setNews: React.Dispatch<React.SetStateAction<INews[]>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) =>
  async () => {
    setLoading(true)
    try {
      await deleteNewsImage(refString)
      await NewsService.deleteNews(id)
      setNews((newsList) => newsList.filter((news) => news.id !== id))
      toast.success('Noticia eliminada correctamente')
    } catch (error) {
      toast.error('Error al eliminar noticia')
    } finally {
      setLoading(false)
    }
  }
export function NewsCard(props: INewsCardProps) {
  const [open, setOpen] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { title, subtitle, date, text, imageUrl, id, refString, favorite } =
    props.news
  const { setNews } = props
  const history = useHistory()
  const goToUpdate = () => () => {
    history.push(`/admin/noticias/update/${id}`)
  }
  const { day, month, year } = getParsedDate(date)

  return (
    <>
      <FlexColumn>
        <ContainerNewsCard>
          <TextContainer>
            <h4>{title}</h4>
            <p>{subtitle}</p>
            {favorite && (
              <TagContainer>
                <Tag color="#2db7f5">Destacada</Tag>
              </TagContainer>
            )}
            <p>{`${day}/${month}/${year}`}</p>
            <ClickableText>
              {!open && <PlusOutlined />}
              <p
                onClick={() => {
                  setOpen((openState) => !openState)
                }}>
                {open ? <>cerrar</> : <>ver mas</>}
              </p>
            </ClickableText>
          </TextContainer>
          <ButtonContainer>
            <Button
              type="default"
              icon={<DeleteOutlined />}
              onClick={() => {
                setOpenDeleteModal(true)
              }}
            />
            <Button
              type="default"
              icon={<FormOutlined />}
              onClick={goToUpdate()}
            />
          </ButtonContainer>
        </ContainerNewsCard>
        <NewsContentCollapse open={open} text={text} url={imageUrl} />
      </FlexColumn>
      <Modal
        visible={openDeleteModal}
        title="Borrar Noticia?"
        centered
        okText="Borrar"
        onOk={confirmDelete(id, refString, setNews, setConfirmLoading)}
        cancelText="Cancelar"
        onCancel={() => {
          setOpenDeleteModal(false)
        }}
        width={400}>
        <>
          {confirmLoading && (
            <ContainerCenter>
              <LoadingOutlined />
            </ContainerCenter>
          )}
        </>
      </Modal>
    </>
  )
}
