import { EditOutlined } from '@ant-design/icons'
import { Empty } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'

import Loader from '../../../../../components/Loader'
import { NewsService } from '../../../../../services/news.service'
import { to } from '../../../../../utils/Async.util'

import { NewsCard } from './NewsCard'
import {
  ContainerNewsList,
  ButtonCreateNews,
  EmptyContainer,
  ContainerUl,
  ListNewsHeader,
} from './styles'

export interface INews {
  id: string
  title: string
  text: string
  subtitle: string
  imageUrl: string
  refString: string
  favorite: boolean
  date: { _seconds: number; _nanoseconds: number }
}
export function NewsList() {
  const [news, setNews] = useState<INews[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const history = useHistory()
  useEffect(() => {
    async function getNews() {
      setLoading(true)
      setError(false)
      const [errorTo, response] = await to(NewsService.listnews())
      if (errorTo) {
        setError(true)
      } else {
        setNews(response)
      }
      setLoading(false)
    }
    getNews()
  }, [])
  if (error) return <Redirect to="/error" />
  if (loading) return <Loader isLoading />
  return (
    <>
      <ContainerNewsList>
        <h1>Lista de noticias</h1>
        {news.length < 1 ? (
          <Empty
            description={
              <EmptyContainer>
                <p>No hay noticias</p>
                <ButtonCreateNews
                  onClick={() => {
                    history.push('noticias/create')
                  }}>
                  Crear Noticia
                </ButtonCreateNews>
              </EmptyContainer>
            }
          />
        ) : (
          <ContainerUl>
            <ListNewsHeader>
              <ButtonCreateNews
                onClick={() => {
                  history.push('noticias/create')
                }}>
                <EditOutlined />
                Crear Noticia
              </ButtonCreateNews>
            </ListNewsHeader>
            <ul>
              {news.map((newsItem) => (
                <li key={newsItem.title}>
                  <NewsCard news={newsItem} setNews={setNews} />
                </li>
              ))}
            </ul>
          </ContainerUl>
        )}
      </ContainerNewsList>
    </>
  )
}
