import userService from '../services/usuarioService.service'

export const downloadFileCSV = async () => {
  const data = await userService.getAllUsers()
  const csv = data.map((user) => {
    const { aprovado, email, jobPosition, nome, telefono, ruts } = user
    return `${aprovado},${email},${jobPosition},${nome},${telefono},${ruts}`
  })
  const csvContent = `data:text/csv;charset=utf-8,${csv.join('\n')}`
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', 'allUsers.csv')
  link.click()
}
