import styled from 'styled-components'

export const ContainerNewsList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  h1 {
    margin: 40px 0 40px 8px;
  }
  ul {
    list-style: none;
  }
  li {
    display: flex;
  }
`
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  width: 20%;
`
export const ContainerNewsCard = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e8e8e8;
  padding: 30px;
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  h4 {
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    font-size: 1rem;
  }
  p {
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
  }
`
export const ClickableText = styled.div`
  display: flex;
  width: 80px;
  gap: 4px;
  margin-top: 10px;
  cursor: pointer;
  color: hsl(222, 95%, 34%);
  p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
    color: hsl(222, 95%, 34%);
  }
  span {
    padding: 4px 0 1px 0;
    font-size: 0.8rem;
  }
  p:hover,
  &:hover {
    color: hsl(222, 95%, 44%);
  }
`
export const NewsContentCollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #e8e8e8;
  p {
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
    text-align: justify;
    text-overflow: ellipsis;
    max-width: 600px;
  }
`
export const ImageNews = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  img {
    object-fit: cover;
    width: 300px;
  }
`
export const ButtonCreateNews = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #064ff9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: 600;
  &:hover {
    background-color: #0a5fff;
  }
  span {
    margin-right: 4px;
  }
`
export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  p {
    margin: 0 10px 0 0;
    font-size: 1rem;
  }
`

export const ContainerUl = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ListNewsHeader = styled.div`
  display: flex;
  margin-bottom: 20px;
`
export const ContainerCenter = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  margin: 20px;
  span {
    color: #e56910;
    font-size: 3rem;
  }
`
export const TagContainer = styled.div`
  display: flex;
  width: 100px;
  margin: 4px 0;
  span {
    margin: 0;
  }
`
export const ParagraphContainer = styled.div`
  display: inline;
  margin: 0 0 3px 0;
  padding: 0;
  p {
    margin: 0;
  }
`
