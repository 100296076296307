import { any, shape, string } from 'prop-types'
import React, { useState } from 'react'

import { useDialog } from '../hooks/useDialog'
import './IPonto.context'

/** @type {PontoContext} */
export const PontoContext = React.createContext({} as any)

/** @type {Ponto} */
const EMPTY_PONTO = { barra: '', clave: '', rut: '', isEmpty: true }

/** @type {PontoProviderProps} */
function PontoProvider({ children }) {
  const [ponto, setPonto] = useState(EMPTY_PONTO)
  const { isDialogOpen, toggleDialog, onCloseDialog, onOpenDialog } =
    useDialog()

  /** @type {ISelectPonto} */
  function selectPonto(selectedPonto) {
    const ponto = selectedPonto || EMPTY_PONTO
    setPonto(ponto)
  }

  function cleanPontoContext() {
    setPonto(EMPTY_PONTO)
  }
  return (
    <PontoContext.Provider
      value={{
        selectPonto,
        ponto,
        isOpenPontoPage: isDialogOpen,
        onClosePontoPage: onCloseDialog,
        onOpenPontoPage: onOpenDialog,
        onTogglePontoPage: toggleDialog,
        cleanPontoContext,
      }}>
      {children}
    </PontoContext.Provider>
  )
}

export const PontoShape = shape({
  barra: string.isRequired,
  clave: string.isRequired,
  rut: string.isRequired,
}).isRequired

PontoProvider.propTypes = {
  children: any,
}

export { PontoProvider }
