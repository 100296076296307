import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import ErrorPage from '../components/ErrorPage'
import Loader from '../components/Loader'
import Admin from '../pages/Admin/'

import AdminRoute from './AdminRoute'
import AuthRoute from './AuthRoute'
const News = lazy(() => import('../pages/News'))
const Facturas = lazy(() => import('../pages/Facturas'))
const NewsDetail = lazy(() => import('../pages/NewsDetail'))
const Login = lazy(() => import('../pages/Login'))
const Register = lazy(() => import('../pages/Register'))
const Home = lazy(() => import('../pages/Home'))
const Profile = lazy(() => import('../pages/Profile'))
const Contact = lazy(() => import('../pages/Contact'))
const MyFees = lazy(() => import('../pages/MyFees'))
const ConsumptionData = lazy(() => import('../pages/ConsumptionData'))
const SimulateConsumption = lazy(() => import('../pages/SimulateConsumption'))
const CompareContracts = lazy(() => import('../pages/CompareContracts'))
const SimulateSeasonality = lazy(
  () => import('../pages/SimulateConsumption/SimulateSeasonality'),
)
const SimulateModulation = lazy(
  () => import('../pages/SimulateConsumption/SimulateModulation'),
)
const InitialPage = lazy(() => import('../pages/InitialPage'))
const DistributorInvoice = lazy(() => import('../pages/DistributorInvoice'))
const Denied = lazy(() => import('../pages/Denied/'))

export default function Routes() {
  return (
    <Router>
      <Suspense fallback={<Loader isLoading />}>
        <Switch>
          <AuthRoute exact path="/home" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <AdminRoute path="/admin">
            <Admin />
          </AdminRoute>
          <AuthRoute path="/profile" component={Profile} />
          <AuthRoute exact path="/news/:id" component={NewsDetail} />
          <AuthRoute exact path="/news" component={News} />
          <AuthRoute exact path="/facturas" component={Facturas} />
          <AuthRoute exact path="/contact" component={Contact} />
          <AuthRoute
            exact
            path="/compare-contracts"
            component={CompareContracts}
          />
          <AuthRoute exact path="/:origin/my-fees" component={MyFees} />
          <AuthRoute
            exact
            path="/:origin/consumption-data"
            component={ConsumptionData}
          />
          <AuthRoute exact path="/simulate" component={SimulateConsumption} />

          <AuthRoute
            exact
            path="/simulate/modulation"
            component={SimulateModulation}
          />

          <AuthRoute
            exact
            path="/simulate/seasonality"
            component={SimulateSeasonality}
          />

          <AuthRoute
            exact
            path="/distributor-invoice"
            component={DistributorInvoice}
          />

          <AuthRoute exact path="/denied" component={Denied} />
          <Route exact path="/error">
            <ErrorPage href="/home" label="Home" />
          </Route>
          <Route component={InitialPage} />
        </Switch>
      </Suspense>
    </Router>
  )
}
