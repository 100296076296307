import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons'
import { Spin, Modal } from 'antd'
import React, { useState } from 'react'

import { deleteFile, getDownloadURL } from '../../../../services/upload.service'

import {
  PopoverContentContainer,
  SpinContainer,
  SecondaryButton,
  DangerButton,
} from './styles'
interface IPopoverContent {
  fullpath: string
  setPath: React.Dispatch<React.SetStateAction<string>>
}
export const PopoverContent = (props: IPopoverContent) => {
  const { fullpath, setPath } = props
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    try {
      setLoading(true)
      await deleteFile(fullpath)
      setLoading(false)
      setPath('*')
    } catch (e) {
      setLoading(false)
    }
  }
  return (
    <PopoverContentContainer>
      {loading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        <>
          <SecondaryButton
            onClick={async () => {
              const link = document.createElement('a')
              link.href = await getDownloadURL(fullpath)
              link.target = '_blank'
              link.click()
            }}>
            <DownloadOutlined />
            Descarga
          </SecondaryButton>
          <DangerButton
            onClick={() => {
              setIsOpen(true)
            }}>
            <DeleteOutlined />
            Borrar
          </DangerButton>
        </>
      )}
      <Modal
        title="Desea eliminar este archivo?"
        visible={isOpen}
        onOk={() => {
          handleDelete()
        }}
        onCancel={() => {
          setIsOpen(false)
        }}>
        {loading ? (
          <SpinContainer>
            <Spin />
          </SpinContainer>
        ) : (
          <p>{fullpath}</p>
        )}
      </Modal>
    </PopoverContentContainer>
  )
}
