import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(3, 38, 119, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    margin: 0;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.8);
  }
  p {
    margin: 0;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.5);
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 10px;
`
export const Button = styled.button`
  border-radius: 4px;
  border: 1px solid rgba(3, 38, 119, 0.5);
  color: rgba(3, 38, 119, 0.8);
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    background-color: #032677;
    color: white;
    border: 1px solid #032677;
  }
`
export const ButtonDelete = styled.button`
  border-radius: 4px;
  border: 1px solid rgba(255, 0, 0, 0.5);
  color: rgba(255, 0, 0, 0.8);
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    background-color: rgba(255, 0, 0, 0.9);
    color: white;
    border: 1px solid rgba(255, 0, 0, 0.9);
  }
`
export const LoadingContainerCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #e56910;
    font-size: 2.5rem;
    font-weight: 800;
  }
`
