/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

import Loader from '../components/Loader'
import { AuthContext } from '../context/Auth.context'

export default function AuthRoute({ component: Component, ...rest }) {
  const { isAuthenticated, user, loading } = useContext(AuthContext)
  if (loading) {
    return <Route render={() => <Loader isLoading />} />
  }
  if (user.isAdmin) {
    return <Redirect to="/admin" />
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  )
}
