import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'

import 'firebase/auth'
import 'firebase/firestore'
import App from './App'

import 'antd/dist/antd.css'
import 'react-toastify/dist/ReactToastify.css'
import './global.css'
import './index.css'

if (process.env.REACT_APP_ERROR_LOG === 'true') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://246ab90c87c449d3b8dbf3259cac020b@o503825.ingest.sentry.io/5741683',
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}
ReactDOM.render(<App />, document.getElementById('root'))
