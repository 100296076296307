/* eslint-disable react/jsx-props-no-spreading */
import { useField } from 'formik'
import React from 'react'

import { InputContainerChk, InputChk, LabelChk } from './styles'
interface CheckInputProps {
  label: string
  name: string
  type: 'checkbox'
  checked: boolean
  setChecked: (checked: boolean) => void
}

export default function InputCheckbox(props: CheckInputProps) {
  const { label, setChecked, checked, ...rest } = props
  const [field] = useField(rest)
  const { onChange } = field
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
    setChecked(!checked)
  }
  return (
    <InputContainerChk>
      <LabelChk htmlFor={props.name}>
        <InputChk
          {...field}
          {...props}
          checked={checked}
          onChange={(e) => {
            handleCheckboxChange(e)
          }}
        />
        <p>{label}</p>
      </LabelChk>
    </InputContainerChk>
  )
}
