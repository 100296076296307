import React from 'react'

import { logout } from '../../../../utils/firebase/firebaseAuth'

import { AvatarPopoverContainer, ButtonAvatarPopover } from './styles'
interface IAvatarPopoverProps {
  email: string
}
export default function AvatarPopover(props: IAvatarPopoverProps) {
  const { email } = props
  return (
    <AvatarPopoverContainer>
      <p>{email}</p>
      <ButtonAvatarPopover
        onClick={() => {
          logout()
        }}>
        <p>Cerrar Sesión</p>
      </ButtonAvatarPopover>
    </AvatarPopoverContainer>
  )
}
