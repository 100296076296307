import {
  IValuesCreateNews,
  IErrorsCreateNews,
} from '../components/Form/Forms/CreateNewsForm'
import { IUserAdminValues } from '../components/Form/Forms/CreateUserAdminForm'
import { IValues } from '../components/Form/Forms/CreateUserForm'
import { IFormValues } from '../components/Form/Forms/UpdateUserForm'

enum ErrorMessages {
  nameRequired = 'Debes ingresar nombre',
  emailRequired = 'Debes ingresar mail',
  emailInvalid = 'Mail no valido',
  passwordRequired = 'Debes ingresar password',
  passwordInvalid = 'Password debe tener almenos 6 caracteres',
  password2Required = 'Debes repetir password',
  password2Invalid = 'Las password no coinciden',
  superKeyRequired = 'Debes ingresar superKey',
  titleRequired = 'Debes ingresar título',
  subtitleRequired = 'Debes ingresar subtítulo',
  textRequired = 'Debes ingresar contenido',
  leadRequired = 'Debes ingresar lead',
  imageRequired = 'Debes ingresar imagen',
}
interface IErrors extends IValues {}

export const validateCreateUser = (values: IValues): IErrors => {
  const errors: IErrors = {} as IErrors
  if (!values.name) {
    errors.name = ErrorMessages.nameRequired
  }
  if (!values.email) {
    errors.email = ErrorMessages.emailRequired
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = ErrorMessages.emailInvalid
  }
  if (!values.password) {
    errors.password = ErrorMessages.passwordRequired
  } else if (values.password.length < 6) {
    errors.password = ErrorMessages.passwordInvalid
  }
  if (!values.passwordConfirm) {
    errors.passwordConfirm = ErrorMessages.password2Required
  } else if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = ErrorMessages.password2Invalid
  }

  return errors
}

export const validateUpdateUser = (values: IFormValues): IFormValues => {
  const errors: IFormValues = {} as IFormValues
  if (!values.name) {
    errors.name = ErrorMessages.nameRequired
  }

  return errors
}

export const validateCreateUserAdmin = (
  values: IUserAdminValues,
): IUserAdminValues => {
  const errors: IUserAdminValues = {} as IUserAdminValues
  if (!values.name) {
    errors.name = ErrorMessages.nameRequired
  }
  if (!values.email) {
    errors.email = ErrorMessages.emailRequired
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = ErrorMessages.emailInvalid
  }
  if (!values.password) {
    errors.password = ErrorMessages.passwordRequired
  } else if (values.password.length < 6) {
    errors.password = ErrorMessages.passwordInvalid
  }
  if (!values.passwordConfirm) {
    errors.passwordConfirm = ErrorMessages.password2Required
  } else if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = ErrorMessages.password2Invalid
  }
  if (!values.superKey) {
    errors.superKey = ErrorMessages.superKeyRequired
  }

  return errors
}

export const validateUpdateUserAdmin = (values: {
  name: string
}): { name: string } => {
  const errors: IUserAdminValues = {} as IUserAdminValues
  if (!values.name) {
    errors.name = ErrorMessages.nameRequired
  }
  return errors
}

export const validateCreateNews = (
  values: IValuesCreateNews,
): IErrorsCreateNews => {
  const errors: IErrorsCreateNews = {} as IErrorsCreateNews
  if (!values.title) {
    errors.title = ErrorMessages.titleRequired
  }
  if (!values.subtitle) {
    errors.subtitle = ErrorMessages.subtitleRequired
  }
  if (!values.text) {
    errors.text = ErrorMessages.textRequired
  }
  if (!values.lead) {
    errors.lead = ErrorMessages.leadRequired
  }
  if (!values.file) {
    errors.file = ErrorMessages.imageRequired
  }

  return errors
}
