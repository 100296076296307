import React from 'react'

import { FileItem } from './FIleItem'
import { ListContainer, UlContainer, Ul } from './styles'

interface IListProps {
  fileList: FileList | null
}
export const FileList = (props: IListProps) => {
  const { fileList } = props
  let list: File[] = []
  if (fileList) {
    list = Array.from(fileList)
  }
  return (
    <>
      <ListContainer>
        {list.length > 0 ? (
          <UlContainer>
            <p>Archivos Seleccionados</p>
            <Ul>
              {list.map((file) => (
                <FileItem key={file.name} file={file} />
              ))}
            </Ul>
          </UlContainer>
        ) : (
          <p>No hay archivos seleccionados</p>
        )}
      </ListContainer>
    </>
  )
}
