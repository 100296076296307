import { HomeOutlined } from '@ant-design/icons'
import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  FirstRowContainer,
  PathContainer,
  BlueButton,
  ContainerButtonPath,
  SecondaryButton,
} from './styles'

interface IPath {
  path: string
  setPath: React.Dispatch<React.SetStateAction<string>>
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const Path = (props: IPath) => {
  const history = useHistory()
  const { path, setPath, toggleModal } = props
  let pathArray: string[] = []
  if (path === '/') {
    pathArray = ['']
  } else {
    pathArray = path.split('/')
  }
  return (
    <FirstRowContainer>
      <PathContainer>
        <HomeOutlined
          onClick={() => {
            setPath('/')
          }}
        />
        <p>/</p>
        {pathArray.map((x, i) => {
          if (x === '') return null
          return (
            <>
              <p
                onClick={() => {
                  let newPath = ''
                  for (let index = 0; index <= i; index++) {
                    if (pathArray[index] === '') continue
                    newPath += `/${pathArray[index]}`
                  }
                  setPath(newPath)
                }}
                key={x}>
                {x}
              </p>

              <p>/</p>
            </>
          )
        })}
      </PathContainer>
      <ContainerButtonPath>
        <SecondaryButton
          onClick={() => {
            history.push('/admin/facturas/search')
          }}>
          Remover con filtro
        </SecondaryButton>
        <BlueButton
          onClick={() => {
            toggleModal(true)
          }}>
          Carga Masiva
        </BlueButton>
      </ContainerButtonPath>
    </FirstRowContainer>
  )
}
