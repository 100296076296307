function getVerificatorDigit(rut) {
  const rutValues = rut.split('')
  const reverseValues = rutValues.reverse()
  const multiplyValues = [2, 3, 4, 5, 6, 7, 2, 3]
  const total = reverseValues
    .map((el, index) => Number(el) * multiplyValues[index])
    .reduce((total, currentElement) => total + currentElement)
  const divided = Math.floor(total / 11)
  const calculated = 11 - (total - 11 * divided)
  if (calculated === 11) return 0
  if (calculated === 10) return 'K'
  return calculated
}

export function isValidRut(value) {
  if (!/^[0-9]+-[0-9kK]{1}$/.test(value)) {
    return false
  }
  const [rut, digv] = value.split('-')
  const calculatedDigit = getVerificatorDigit(rut)
  return calculatedDigit.toString() === digv
}
const phoneRe =
  /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/
export function isValidPhone(p) {
  const digits = p.replace(/\D/g, '')
  return phoneRe.test(digits)
}

const emailPattern =
  /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/
export function isEmail(email) {
  return emailPattern.test(email)
}
