import styled from 'styled-components'
export const ContainerDrawer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`
export const ContainerSearch = styled.div`
  display: flex;
  padding-bottom: 3rem;
  gap: 1rem;
`
export const H1 = styled.h1`
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 0.15px;
  padding: 3rem 0;
`
export const SuperAdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const SuperAdminCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  background-color: white;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
  padding: 2rem;
`
export const FacturaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const SuperContainer = styled(FacturaContainer)`
  h1 {
    font-size: 1.5rem;
    margin: 1rem 0 0 0;
  }
`

export const GridUser = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
`
export const ButtonContainer = styled.div`
  display: flex;
  margin: 1.5rem 0;
  button {
    padding: 6px 10px;
    border-radius: 4px;
    border: 1px solid rgba(3, 38, 119, 0.5);
    color: rgba(3, 38, 119, 0.8);
    background-color: white;
    cursor: pointer;
    font-weight: 500;
  }
  button:hover {
    background-color: #032677;
    color: white;
    border: 1px solid #032677;
  }
`
