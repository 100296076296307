import { any } from 'prop-types'
import React, { useState } from 'react'

import { ISimulationContext } from './ISimulation.context'

const simulationContextValue: ISimulationContext = {} as any
export const SimulationContext = React.createContext(simulationContextValue)

/** @type {SimulationProviderProps} */
function SimulationProvider({ children }) {
  const [name, setName] = useState('')
  const [seasonalityId, setSeasonalityId] = useState('')
  const [modulationId, setModulationId] = useState('')

  return (
    <SimulationContext.Provider
      value={
        {
          setSimulationName: setName,
          simulationName: name,
          selectModulation: setModulationId,
          selectSeasonality: setSeasonalityId,
          modulationId,
          seasonalityId,
        } as any
      }>
      {children}
    </SimulationContext.Provider>
  )
}

SimulationProvider.propTypes = {
  children: any,
}

export { SimulationProvider }
