import { isValidRut } from './Validate.util'
export const fileNameToURLStorage = (fileName: string): string => {
  const chunks = fileName.split('-')
  if (chunks.length === 4) {
    const shortFileName = chunks[3]
    const month = chunks[2]
    const year = chunks[1]
    const rut = chunks[0]
    return `${rut}/${year}/${month}/${shortFileName}`
  }
  return ''
}

export const convertBytesToReadable = (bytes: number) => {
  if (bytes < 1024) {
    return `${bytes} B`
  }
  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`
  }
  if (bytes < 1024 * 1024 * 1024) {
    return `${(bytes / 1024 / 1024).toFixed(2)} MB`
  }
  return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} GB`
}

export const isValidMasiveUploadFileName = (file: File) => {
  const fileName = file.name
  const chunks = fileName.split('-')
  if (chunks.length !== 4) {
    return false
  }
  const rut = chunks[0]
  const year = chunks[1]
  const month = chunks[2]
  const regexYear = /^[0-9]{4}$/
  if (!regexYear.test(year) || parseInt(year) < 2010) {
    return false
  }
  const regexMonth = /^(0|1)[0-9]$/
  if (!regexMonth.test(month) || parseInt(month) < 1 || parseInt(month) > 12) {
    return false
  }
  const rutWithDash = rut.slice(0, -1) + '-' + rut.slice(-1)
  if (!isValidRut(rutWithDash)) {
    return false
  }
  return true
}
