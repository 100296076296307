import React from 'react'

import { FileViewer } from '../components/Facturas/FileViewer'

import { FacturaContainer } from './styles'
export function Facturas() {
  return (
    <FacturaContainer>
      <FileViewer />
    </FacturaContainer>
  )
}
