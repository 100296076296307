import React, { useEffect, useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'

import { UpdateNewsForm } from '../../../../../components/Form/Forms/UpdateNewsForm'
import Loader from '../../../../../components/Loader'
import { NewsService, TNewsFromAPI } from '../../../../../services/news.service'
import { to } from '../../../../../utils/Async.util'

import { ContainerNews } from './styles'

export function NewsUpdate() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<TNewsFromAPI | null>(null)
  const [error, setError] = useState(false)
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    const fetchNewId = async () => {
      setLoading(true)
      setError(false)
      const [errorTo, response] = await to(NewsService.getNewsById(id))
      if (errorTo) {
        setError(true)
      } else {
        setData(response)
      }
      setLoading(false)
    }
    fetchNewId()
  }, [])

  if (error) {
    return <Redirect to="/error" />
  }
  if (loading) {
    return <Loader isLoading />
  }

  return (
    <ContainerNews>
      {data && <UpdateNewsForm setLoading={setLoading} initValues={data} />}
    </ContainerNews>
  )
}
