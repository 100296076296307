import React from 'react'
import { useHistory } from 'react-router-dom'

import LogoColorido from '../../assets/logo_colorido.png'

import { ErrorPageContainer, CardError, Logo, Button } from './styles'
interface ErrorPageProps {
  href: string
  label: string
}
export default function ErrorPage(prop: ErrorPageProps) {
  const { href, label } = prop
  const history = useHistory()
  return (
    <ErrorPageContainer>
      <CardError>
        <Logo src={LogoColorido}></Logo>
        <h2>Oops parece que hubo un error </h2>
        <Button
          onClick={() => {
            history.push(href)
          }}>
          Volver a {label}
        </Button>
      </CardError>
    </ErrorPageContainer>
  )
}
