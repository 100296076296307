import { Button } from 'antd'
import { Formik, Form, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { NewsService } from '../../../services/news.service'
import { createNewsImage } from '../../../services/upload.service'
import { validateCreateNews } from '../../../utils/Error.utils'
import InputCheckbox from '../CustomInputs/InputCheckbox'
import InputFileNews from '../CustomInputs/InputFileNews'
import InputText from '../CustomInputs/InputText'
import InputTextArea from '../CustomInputs/InputTextArea'

export interface IValuesCreateNews {
  title: string
  subtitle: string
  lead: string
  text: string
  file: string
}

export interface IErrorsCreateNews extends IValuesCreateNews {}

const initValues: IValuesCreateNews = {
  title: '',
  subtitle: '',
  text: '',
  file: '',
  lead: '',
}

interface ICreateNewsFormProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}
export const CreateNewsForm = (props: ICreateNewsFormProps) => {
  const history = useHistory()
  const { setLoading } = props
  const [file, setFile] = useState<File | null>(null)
  const [checked, setChecked] = useState<boolean>(false)
  const onSubmit = async (
    values: IValuesCreateNews,
    actions: FormikHelpers<IValuesCreateNews>,
  ) => {
    setLoading(true)
    if (file) {
      try {
        const { url, refString } = await createNewsImage(file)
        await NewsService.create({
          ...values,
          imageUrl: url,
          refString,
          favorite: checked,
        })
        toast.success('Noticia creada con éxito')
        history.push('/admin/noticias')
      } catch (err) {
        toast.error('Error al crear noticia')
      }
    }
    setLoading(false)
    setChecked(false)
    setFile(null)
    actions.setSubmitting(false)
    actions.resetForm()
  }

  return (
    <Formik
      initialValues={initValues}
      validate={validateCreateNews}
      onSubmit={onSubmit}>
      <Form>
        <InputText
          label="Título"
          name="title"
          type="text"
          placeholder="Título noticia"
        />
        <InputText
          label="Subtítulo"
          name="subtitle"
          type="text"
          placeholder="Subtítulo noticia"
        />
        <InputText
          label="Lead"
          name="lead"
          type="text"
          placeholder="Lead noticia"
        />
        <InputTextArea
          label="Contenido"
          name="text"
          type="text"
          placeholder="Contenido de la noticia"
        />
        <InputFileNews
          name="file"
          type="file"
          accept="image/*"
          file={file}
          setFile={setFile}
        />
        <InputCheckbox
          checked={checked}
          setChecked={setChecked}
          label="Marcar como favorita"
          type="checkbox"
          name="favorite"
        />
        <Button htmlType="submit" type="primary" size="large">
          Crear Noticia
        </Button>
      </Form>
    </Formik>
  )
}
