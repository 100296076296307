import firebase from 'firebase'

import { fileNameToURLStorage } from '../utils/File.util'

const ref = firebase.storage().ref()

export type TStorageFileInfo = { fullpath: string; name: string }

export const NEWSIMAGE_ROOT = 'newsimages'

export async function uploadFile(file: File) {
  const fileName = `${new Date().getTime()}-${file.name}`
  const doc = await ref.child(fileName).put(file)
  const url: string = await doc.ref.getDownloadURL()
  return url
}

export async function uploadBatch(file: File): Promise<void> {
  const url = fileNameToURLStorage(file.name)
  const fileRef = ref.child(url)
  await fileRef.put(file)
}

export async function list(path: string) {
  const list = await ref.child(path).listAll()
  return list
}

export async function getAllFilesDownTree(
  path: string,
  ignoreDirectory?: string,
) {
  const listFiles: Array<string> = []
  const listPrefixItem = await list(path)
  if (listPrefixItem.items.length > 0) {
    for (const item of listPrefixItem.items) {
      listFiles.push(item.fullPath)
    }
  }
  if (listPrefixItem.prefixes.length > 0) {
    for (const prefix of listPrefixItem.prefixes) {
      if (ignoreDirectory && prefix.name === ignoreDirectory) {
        continue
      } else {
        const listFilesPrefix = await getAllFilesDownTree(
          prefix.fullPath,
          ignoreDirectory,
        )
        listFiles.push(...listFilesPrefix)
      }
    }
  }
  return listFiles
}

export async function deleteFile(path: string) {
  await ref.child(path).delete()
}

export async function getDownloadURL(path: string) {
  const url = await ref.child(path).getDownloadURL()
  return url
}

export async function createNewsImage(file: File) {
  const refString = `${NEWSIMAGE_ROOT}/${new Date().getTime()}_${file.name}`
  const fileRef = ref.child(refString)
  await fileRef.put(file)
  const url = await fileRef.getDownloadURL()
  return { url, refString }
}
export async function deleteNewsImage(refString: string) {
  await ref.child(refString).delete()
}

export async function updateNewsImage(file: File, refString: string) {
  await deleteNewsImage(refString)
  const { url, refString: newRefString } = await createNewsImage(file)
  return { url, refString: newRefString }
}

export async function getFacturesFromRut(pathRut: string) {
  const listWea: Array<TStorageFileInfo> = []
  const listFiles = await list(pathRut)
  const { prefixes: listRefsYear } = listFiles
  if (listRefsYear.length === 0) {
    return []
  }
  for (const refYear of listRefsYear) {
    const listFilesYear = await list(refYear.fullPath)
    const { prefixes: listRefsMonth } = listFilesYear
    if (listRefsMonth.length === 0) {
      break
    }
    for (const refMonth of listRefsMonth) {
      const listFilesMonth = await list(refMonth.fullPath)
      const { items } = listFilesMonth
      if (items.length === 0) {
        break
      }
      for (const item of items) {
        listWea.push({ fullpath: item.fullPath, name: item.name })
      }
    }
  }
  return listWea
}
