import firebase from 'firebase'
import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { list, NEWSIMAGE_ROOT } from '../../../../services/upload.service'

import { ListFolder } from './ListFolder'
import { ContainerTab, TitleFacturas } from './styles'
export interface IFile {
  ref: firebase.storage.Reference
  metadata: firebase.storage.FullMetadata
}
type arrayOfRefs = firebase.storage.Reference[]
export function FileViewer() {
  const [prefixes, setPrefixes] = useState<arrayOfRefs>([])
  const [items, setItems] = useState<IFile[]>([])
  const [path, setPath] = useState<string>('/')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  useEffect(() => {
    const getFolders = async () => {
      if (path === '*') {
        setPath('/')
      } else {
        setLoading(true)
        setError(false)
        try {
          const listRefs = await list(path)
          const { prefixes, items } = listRefs
          setPrefixes(
            prefixes.filter(
              (directory) => directory.fullPath !== NEWSIMAGE_ROOT,
            ),
          )
          const itemList: IFile[] = []
          if (items.length > 0) {
            const listPromisesMetadata = items.map(async (ref) =>
              ref.getMetadata(),
            )
            const listMetadata = await Promise.all(listPromisesMetadata)
            for (let i = 0; i < listMetadata.length; i++) {
              const metadata = listMetadata[i]
              const ref = items[i]
              itemList.push({ ref, metadata })
            }
          }
          setItems(itemList)
        } catch (e) {
          setError(true)
        } finally {
          setLoading(false)
        }
      }
    }
    getFolders()
  }, [path])

  if (error) return <Redirect to="/error" />

  return (
    <ContainerTab>
      <TitleFacturas>Facturas</TitleFacturas>
      <ListFolder
        prefixes={prefixes}
        items={items}
        setPath={setPath}
        path={path}
        loading={loading}
      />
    </ContainerTab>
  )
}
