import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

import Loader from '../components/Loader'
import { AuthContext } from '../context/Auth.context'

interface AdminRouteProps {
  children: React.ReactNode
  path: string
}

export default function AdminRoute(props: AdminRouteProps) {
  const { children, path } = props
  const { user, loading } = useContext(AuthContext)
  if (loading) {
    return <Route render={() => <Loader isLoading />} />
  }
  if (!user.isAdmin) {
    return <Redirect to="/home" />
  }
  return <Route path={path}>{children}</Route>
}
